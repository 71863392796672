import React, { Component } from 'react'
import InputField from '../../components/InputField'
import './index.css'
import { withRouter } from 'react-router-dom'
import { ButtonField } from '../../components/ButtonField';

class Register extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            password: '',
            handphone: '',
            showPassword: false,
        };
    }

    onChange = (id, value) => {
        switch (id) {
            case 'email':
                this.setState({ email: value })
                break;
            case 'password':
                this.setState({ password: value })
                break;
            case 'name':
                this.setState({ name: value })
                break;
            case 'handphone':
                this.setState({ handphone: value })
                break;
            default:
                break;
        }
    }

    render() {
        const { showPassword } = this.state;
        return (
            <div className='parent-container-register'>
                <div className='form-container-register'>
                    <div className='header-container'>
                        <p className='header-title'>Halo, Selamat datang di Iwa K Bimbel!</p>
                        <p className='header-subtitle'>Sebelum kita mulai belajar, kita kenalan dulu yuk!</p>
                    </div>
                    <div className='input-container-register'>
                        <label>Nama</label>
                        <InputField
                            id="name"
                            type='text'
                            enabled={true}
                            value={this.state.name}
                            placeholder="Masukkan nama kamu"
                            onChange={this.onChange}
                        />
                    </div>
                    <div className='input-container-register'>
                        <label>Email</label>
                        <InputField
                            id="email"
                            type='text'
                            enabled={true}
                            value={this.state.email}
                            placeholder="Masukkan email kamu"
                            onChange={this.onChange}
                        />
                    </div>
                    <div className='input-container-register'>
                        <div className='label-password-container-register'>
                            <label>Password</label>
                            <label className='label-show-password-register' onClick={() => this.setState({ showPassword: !this.state.showPassword })}>{showPassword ? 'Sembunyikan' : 'Tampilkan'}</label>
                        </div>
                        <InputField
                            id="password"
                            type={showPassword ? 'text' : 'password'}
                            enabled={true}
                            value={this.state.email}
                            placeholder="Masukkan password kamu"
                            onChange={this.onChange}
                        />
                    </div>
                    <div className='input-container-register'>
                        <label>Nomor Telepon</label>
                        <InputField
                            id="handphone"
                            type='numeric'
                            enabled={true}
                            value={this.state.handphone}
                            placeholder="Masukkan nomor handphone kamu"
                            onChange={this.onChange}
                        />
                    </div>
                    <div className='input-container-register'>
                        <label>Tanggal Lahir</label>
                        <InputField
                            id="handphone"
                            type='numeric'
                            enabled={true}
                            value={this.state.handphone}
                            placeholder="Pilih tanggal lahir"
                            onChange={this.onChange}
                        />
                    </div>
                    <div className='input-container-register'>
                        <label>Jenis Kelamin</label>
                        <InputField
                            id="handphone"
                            type='numeric'
                            enabled={true}
                            value={this.state.handphone}
                            placeholder="Pilih jenis kelamin"
                            onChange={this.onChange}
                        />
                    </div>
                    <div className='input-container-register'>
                        <label>Provinsi</label>
                        <InputField
                            id="handphone"
                            type='numeric'
                            enabled={true}
                            value={this.state.handphone}
                            placeholder="Pilih provinsi"
                            onChange={this.onChange}
                        />
                    </div>
                    <div className='button-container-form-register'>
                        <ButtonField
                            label='Daftar'
                            labelColor='#ffffff'
                            onClick={() => this.props.history.push('/dashboard')}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Register)
