import Axios from 'axios'
import auth from './actions/authActions'
import tryout from './actions/tryoutActions';

const setCookie = (cname, cvalue, hours) => {
    var d = new Date();
    d.setTime(d.getTime() + (hours * 60 * 60 * 1000));
    document.cookie = cname + "=" + cvalue + "; expires=" + d.toUTCString();
}

const getCookie = (cname) => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

const instance = Axios.create();
instance.defaults.baseURL = "https://admin.bimbeliwa.com/api/tryout";
instance.defaults.headers = {
    "Content-Type": "application/json",
    Authorization: getCookie("token"),
}

const setToken = (token) => {
    setCookie("token", token, 18);
    instance.defaults.headers.Authorization = token;
}

const unsetToken = () => {
    setCookie("token", "", -1);
    instance.defaults.headers.Authorization = '';
}

const responseData = res => res.data;
const requests = {
    get: url => instance({ method: "GET", url }).then(responseData),
    post: (url, data) => instance({ method: "POST", url, data }),
    put: (url, data) => instance({ method: "PUT", url, data }),
    delete: (url) => instance({ method: 'DELETE', url }).then(responseData),
}

export default {
    setCookie,
    getCookie,
    setToken,
    unsetToken,
    auth: auth(requests),
    tryout: tryout(requests),
}
