const initState = {
    list_tryout: [],
    list_susulan: [],
    time_left: 0,
    tka_settings: [],
    tps_settings: [],
    tps_order: ["penalaran_umum", "pemahaman_baca_tulis", "pengetahuan_pemahaman_umum", "pengetahuan_kuantitatif"],
    tka_order: ["matematika", "fisika", "kimia", "biologi"],
    id_tryout: '',
    id_answer: '',
    id_question: '',
    current_answers: [],
    current_questions: [],
    current_topic:'',
    token_tryout: '',
    order: 0,
    tryout_failed: false,
    start_number: 0,
    nomor: 0,
    numbers: [],
    error_messages: '',
}

const tryoutReducer = (state = initState, action) => {
    switch (action.type) {
        case "GET_LIST_TRYOUT_SUCCESS":
            console.log(action.type + ": ");
            console.log(action.res);
            return {
                ...state,
                list_tryout: action.res.tryout,
                list_susulan: action.res.susulan,
                error_messages:'',
            }
        case "GET_LIST_TRYOUT_FAILED":
            console.log(action.type + ": ");
            console.log(action.err);
            return {
                ...state,
                error_messages: action.err,
            }
        case "START_TRYOUT_SUCCESS":
            console.log(action.type + ": ");
            console.log(action.res);
            localStorage.setItem("token_tryout", action.res.data.token);
            return {
                ...state,
                token_tryout: action.res.data,
                error_messages:'',
            }
        case "START_TRYOUT_FAILED":
            console.log(action.type + ": ");
            console.log(action.err);
            return {
                ...state,
                error_messages:action.err,
            }
        case "GET_TRYOUT_SUCCESS":
            console.log(action.type + ": ");
            console.log(action.res);
            return {
                ...state,
                time_left: action.res.time_left,
                id_tryout: action.res.id,
                id_answer: action.res.id_jawaban,
                current_topic: action.res.matpel,
                current_questions: Object.values(action.res.soal),
                numbers: Object.keys(action.res.soal),
                start_number: action.res.nomor - Object.values(action.res.soal).length,
                nomor: action.res.nomor,
                tryout_failed: false,
                error_messages:'',
                order: action.res.order,
            }

        case "GET_TRYOUT_FAILED":
            console.log(action.type + ": ");
            console.log(action.err);
            return {
                ...state,
                tryout_failed: true,
                error_messages: action.err,
            }
        
        case "GET_TRYOUT_ANSWER_SUCCESS":
            console.log(action.type + ": ");
            console.log(action.res);
            console.log(action.res.jawaban);
            if(action.res){
                if(action.res.jawaban !== null && action.res.jawaban !== undefined) {
                    return {
                        ...state,
                        current_answers: action.res.jawaban.split(';'),
                        error_messages: ''
                    }
                } else {
                    return {
                        ...state,
                        current_answers: null,
                        error_messages: ''
                    }
                }
            }
            return {
                ...state,
                current_answers: null,
                error_messages: ''
            }

        case "GET_TRYOUT_ANSWER_FAILED":
            console.log(action.type + ": ");
            console.log(action.err);
            return {
                ...state,
                error_messages: action.err
            }

        case "POST_TRYOUT_SUCCESS":
            console.log(action.type + ": ");
            console.log(action.res);
            return {
                ...state,
                error_messages:'',
            }

        case "POST_TRYOUT_FAILED":
            console.log(action.type + ": ");
            console.log(action.err);
            return {
                ...state,
                error_messages: action.err,
            }

        default:
            return state;
    }
};

export default tryoutReducer;