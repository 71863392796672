import { combineReducers } from "redux"

import authReducer from "./authReducer"
import tryoutReducer from "./tryoutReducers";

const rootReducer = combineReducers({
    auth: authReducer,
    tryout: tryoutReducer
});

export default rootReducer;