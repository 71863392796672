import React from 'react'
import './index.css'

export const ButtonField = (props) => {
    const { onClick, backgroundColor, label, labelColor } = props;
    return (
        <div className='button-container' style={{backgroundColor: backgroundColor ? backgroundColor : ''}} onClick={onClick}>
            <label className='label-style' style={{color: labelColor ? labelColor : ''}}>{label}</label>
        </div>
    )
}
