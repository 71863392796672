const isVerified = async (context) => {
    await context.props.GetUserInfo();

    if (context.props.auth.user_info) {
        return true;
    }

    return false;
}

export { isVerified }