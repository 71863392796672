import React, { Component } from 'react';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ButtonField } from '../../components/ButtonField';
import { ModalField } from '../../components/ModalField';
import InputField from '../../components/InputField';
import { GetTodayDate } from '../Dashboard/actions';
import requests from '../../redux/requests';
import '../Dashboard/index.css';

class Tutorial extends Component {
    constructor(props) {
        super(props);

        this.state = {
            answers: ["-", "-", "-", "-", "-"],
            hideNavbar: false,
            closeNavbar: false,
            closeQuestions: false,
            hideQuestions: true,
            startNumber: 0,
            currentQuestion: 0,
            showModal: false,
            pin: '1234',
            loading: false,
            startTryout: false,
            errorMessage: '',
            mobileMode: false,
            loadingTryout: false,
            questions: [{ "soal": "https://admin.bimbeliwa.com/media/Matematika_No.1.jpeg", "teks": "Apakah Anda mengerti tutorial ini?" }, { "soal": "https://admin.bimbeliwa.com/media/Matematika_No.1.jpeg", "teks": "Apakah Anda mengerti tutorial ini?" }, { "soal": "https://admin.bimbeliwa.com/media/Matematika_No.1.jpeg", "teks": "Apakah Anda mengerti tutorial ini?" }, { "soal": "https://admin.bimbeliwa.com/media/Matematika_No.1.jpeg", "teks": "Apakah Anda mengerti tutorial ini?" }, { "soal": "https://admin.bimbeliwa.com/media/Matematika_No.1.jpeg", "teks": "Apakah Anda mengerti tutorial ini?" }],
            status: [0, 0, 0, 0, 0],
            topicLabel: 'MATEMATIKA',
            durationLabel: "00:00",
            stepIndex: 0,
            showTryout: false,
            changePack: false,
            startedTryout: false,
            finishedTryout: false,
            waiting: false,
            quitTryout: false,
            switchTimeLabel: false,
            initialized: false,
            recovered: false,
            waitTime: false,
            run: false,
            steps: [
                {
                    content: <h2>Pengenalan Try Out IWA K</h2>,
                    locale: { skip: <strong aria-label="skip" style={{ padding: 4, color: "white", backgroundColor: "#2a9df4", borderRadius: 4, fontSize: "smaller" }}>Saya sudah mengetahui</strong> },
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    placement: 'center',
                    target: 'body',
                },
                {
                    content: 'Berikut merupakan informasi mengenai akun Anda yang terlogin',
                    placement: 'bottom',
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    styles: {
                        options: {
                            width: 300,
                        },
                    },
                    target: '.navbar-profile',
                    title: 'Informasi Pengguna',
                },
                {
                    content: 'Pada tabel ini terdapat list try out yang tersedia pada hari ini',
                    floaterProps: {
                        disableAnimation: true,
                    },
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    spotlightPadding: 20,
                    target: '.content-tryout',
                    title: 'Tabel Try Out'
                },
                {
                    content: "Tekan tombol <Kerjakan> apabila ingin memulai try out tersebut",
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    placement: 'bottom',
                    spotlightClicks: true,
                    floaterProps: {
                        disableAnimation: true,
                    },
                    hideFooter: true,
                    styles: {
                        options: {
                            zIndex: 10000,
                        },
                    },
                    spotlightPadding: 20,
                    target: '.table-action',
                    title: 'Kerjakan Try Out'
                },
                {
                    content: <div style={{ fontSize: "small" }}><p>Setelah Anda mengisi pin tryout, Anda akan segera memasuki halaman tryout.</p> <p>Perhatikan! Apabila waktu tryout belum dimulai, maka Anda harus menunggu pada halaman tryout sampai tryout dimulai</p></div>,
                    floaterProps: {
                        disableAnimation: true,
                    },
                    disableOverlayClose: true,
                    spotlightPadding: 20,
                    hideCloseButton: true,
                    placement: 'right',
                    target: '.modal-container',
                    title: 'Memulai Try Out'
                },
                {
                    content: "Silahkan klik OK",
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    placement: 'bottom',
                    spotlightClicks: true,
                    floaterProps: {
                        disableAnimation: true,
                    },
                    hideFooter: true,
                    styles: {
                        options: {
                            zIndex: 10000,
                        },
                    },
                    spotlightPadding: 20,
                    target: '#button-start',
                    title: 'Memulai Try Out'
                },
                {
                    content: 'Dapat terlihat mata pelajaran yang sedang dikerjakan, serta waktu yang tersisa sebelum otomatis melanjutkan ke mata pelajaran selanjutnya',
                    floaterProps: {
                        disableAnimation: true,
                    },
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    spotlightPadding: 20,
                    target: '.navbar-time',
                    title: 'Mata Pelajaran dan Waktu'
                },
                {
                    content: 'Soal akan ditampilkan pada bagian ini, silahkan dibaca dengan seksama',
                    floaterProps: {
                        disableAnimation: true,
                    },
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    spotlightPadding: 20,
                    target: '.content-tryout-container',
                    title: 'Pengerjaan Soal'
                },
                {
                    content: "Tekan salah satu opsi untuk menjawab pertanyaan",
                    disableBeacon: true,
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    placement: 'bottom',
                    spotlightClicks: true,
                    floaterProps: {
                        disableAnimation: true,
                    },
                    hideFooter: true,
                    styles: {
                        options: {
                            zIndex: 10000,
                        },
                    },
                    spotlightPadding: 20,
                    target: '.content-answers-button-group',
                    title: 'Menjawab Pertanyaan'
                },
                {
                    content: 'Pada bagian ini merupakan keterangan nomor soal yang sedang ditampilkan',
                    floaterProps: {
                        disableAnimation: true,
                    },
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    spotlightPadding: 20,
                    target: '.navbar-question-number',
                    title: 'Nomor Soal'
                },
                {
                    content: 'Anda dapat menavigasikan ke soal selanjutnya maupun sebelumnya, selama waktu pengerjaan belum selesai',
                    floaterProps: {
                        disableAnimation: true,
                    },
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    spotlightPadding: 20,
                    target: '.content-navigator',
                    title: 'Navigasi Soal'
                },
                {
                    content: 'Anda pun dapat menavigasikan pada soal tertentu yang ingin Anda kerjakan',
                    floaterProps: {
                        disableAnimation: true,
                    },
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    spotlightPadding: 20,
                    target: '.navbar-questions',
                    title: 'Navigasi Soal'
                },
                {
                    content: 'Apabila Anda selesai sebelum waktunya habis, Anda dapat menyelesaikan dengan menekan tombol ini. Perhatikan! Anda tidak dapat melanjutkan pengerjaan soal apabila telah menekan tombol ini dan Anda akan tetap menunggu hingga waktu selesai',
                    floaterProps: {
                        disableAnimation: true,
                    },
                    disableOverlayClose: true,
                    hideCloseButton: true,
                    spotlightPadding: 20,
                    target: '.navbar-submit',
                    title: 'Selesai Pengerjaan'
                },
                {
                    content: 'Terima Kasih! Selamat mengerjakan',
                    placement: 'center',
                    floaterProps: {
                        disableAnimation: true,
                    },
                    disableOverlayClose: true,
                    spotlightPadding: 20,
                    target: 'body'
                },
            ],
        }
    }

    async componentDidMount() {
        window.addEventListener("resize", this.resize);
        this.resize();

        await this.props.GetUserInfo();

        if (this.props.auth.user_info) {
            this.setState({ run: true })
        }
    }

    resize = () => {
        this.setState({
            hideNavbar: window.innerWidth <= 768 ? true : false,
            mobileMode: window.innerWidth <= 768 ? true : false,
            closeNavbar: false
        });
    }

    // handleLogout = async () => {
    //     alert("Ini hanya tutorial, akan kembali ke dashbord");
    //     this.props.history.replace("/dashboard");
    // }

    handleStartTryout = async () => {

    }

    handlePrev = () => {
        let prevQuestion = this.state.currentQuestion - 1;
        if (prevQuestion < 0) {
            prevQuestion = this.state.questions.length - 1;
        }

        this.setState({ currentQuestion: prevQuestion });
    }

    handleNext = () => {
        let nextQuestion = this.state.currentQuestion + 1;
        if (nextQuestion === this.state.questions.length) {
            nextQuestion = 0;
        }

        this.setState({ currentQuestion: nextQuestion });
    }

    onChange = (id, value) => {
        this.setState({ pin: value.toLowerCase() });
    }

    handleJoyrideCallback = (data) => {
        const { action, index, type, status } = data;
        if (([STATUS.FINISHED, STATUS.SKIPPED]).includes(status)) {
            // this.setState({ run: false, stepIndex: 0 });
            this.props.history.replace("/dashboard");
        } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND]).includes(type)) {
            const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
            // const stepIndex = index + 1;


            if (index === 3 && this.state.showModal && !EVENTS.STEP_BEFORE) {
                setTimeout(() => {
                    this.setState({ run: true, stepIndex })
                }, 400)
            } else if (index === 5 && this.state.showModal) {
                this.setState({ run: false })
                setTimeout(() => {
                    this.setState({ showModal: false, stepIndex, run: true })
                }, 1000)
            } else if (index === 6) {
                setTimeout(() => {
                    this.setState({ run: true, stepIndex })
                }, 400)
            }
            else {
                this.setState({ stepIndex })
            }
        }
    }

    handleAnswers = (e) => {
        var currentAnswer = this.state.answers;
        if (currentAnswer[this.state.currentQuestion] === e.target.value) {
            currentAnswer[this.state.currentQuestion] = "-";
        } else {
            currentAnswer[this.state.currentQuestion] = e.target.value;
        }
        e.target.blur();
        var stepIndex = this.state.stepIndex + 1;

        this.setState({ answers: currentAnswer, stepIndex });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
    }

    render() {
        const {
            answers,
            hideNavbar,
            closeNavbar,
            currentQuestion,
            showModal,
            pin,
            loading,
            startTryout,
            stepIndex,
            mobileMode,
            errorMessage,
            showTryout,
            status,
            loadingTryout,
            questions,
            switchTimeLabel,
            startedTryout,
            startNumber,
            changePack,
            finishedTryout,
            hideQuestions,
            durationLabel,
            topicLabel,
            waitTime,
            waiting,

        } = this.state;
        const username = this.props.auth.user_info ? this.props.auth.user_info.nama : "Unknows";
        const noinduk = this.props.auth.user_info ? this.props.auth.user_info.noinduk : "000000";
        const sekolah = this.props.auth.user_info ? this.props.auth.user_info.sekolah : "School";
        const questionImg = questions[currentQuestion] ? questions[currentQuestion].soal : '';
        const questionText = questions[currentQuestion] ? questions[currentQuestion].text : '';
        const questionOption = questions[currentQuestion] ? questions[currentQuestion].sampai_e : false;
        const todayDate = GetTodayDate();
        return (
            <div className="parent-container">
                <Joyride
                    continuous={true}
                    run={this.state.run}
                    scrollToFirstStep={true}
                    disableCloseOnEsc={true}
                    hideBackButton={true}
                    showProgress={true}
                    showSkipButton={true}
                    stepIndex={this.state.stepIndex}
                    steps={this.state.steps}
                    callback={this.handleJoyrideCallback}
                    styles={{
                        options: {
                            zIndex: 10000,
                            primaryColor: "#c21807"
                        },
                    }}
                />
                {showTryout ?
                    <div className="parent-container-tryout">
                        <div className="header-tryout">
                            {mobileMode ?
                                <div className="navbar-submit" onClick={this.handleSubmit}>
                                    <img alt="Submit" src={require('../../assets/images/ic_checklist.png')} />
                                    <label className={mobileMode ? "hidden" : "navbar-submit-label"}>SELESAI</label>
                                </div>
                                : <label style={{ fontWeight: "bold" }}>Tryout Iwa K</label>
                            }
                            <div
                                className="navbar-question-number"
                                style={{
                                    backgroundColor: status[currentQuestion] ? "#E8a317" : answers[currentQuestion] && answers[currentQuestion] !== "-" && mobileMode ? "#2a9df4" : "#ffffff",
                                    borderColor: status[currentQuestion] ? "#E8a317" : answers[currentQuestion] && answers[currentQuestion] !== "-" && mobileMode ? "#2a9df4" : "#32b4a6",
                                    color: status[currentQuestion] ? "#ffffff" : answers[currentQuestion] && answers[currentQuestion] !== "-" && mobileMode ? "#ffffff" : "#000000",
                                }}
                                onClick={() => {
                                    console.log("showQuestion!")
                                    this.setState({ hideQuestions: false })
                                }
                                }>
                                {!mobileMode ? <span style={{ fontSize: "small" }}>Nomor Soal</span> : null}
                                {parseInt(startNumber) + parseInt(currentQuestion) + 1}
                            </div>
                            <div className="navbar-time" onClick={!mobileMode ? null : () => this.setState({ switchTimeLabel: !this.state.switchTimeLabel })}>
                                <label className={mobileMode && !switchTimeLabel ? "navbar-title hidden" : "navbar-title"}>{topicLabel}</label>
                                <label id="time-label" className={mobileMode && switchTimeLabel ? "navbar-time-label hidden" : "navbar-time-label"}>{durationLabel}</label>
                            </div>
                        </div>
                        <div className="navbar-tryout">
                            <div className="navbar-profile-tryout">
                                <img alt="profile-img" src={require('../../assets/images/ic_ava.png')} />
                                <label>{username}</label>
                                <label style={{ fontSize: "small" }}>{noinduk}</label>
                                <label style={{ fontSize: "small" }}>{sekolah}</label>
                            </div>
                            {mobileMode && hideQuestions ?
                                <div
                                    className="navbar-questions-mobile"
                                    style={{
                                        backgroundColor: status[currentQuestion] ? "#E8a317" : answers[currentQuestion] ? "#2a9df4" : "#ffffff",
                                        borderColor: status[currentQuestion] ? "#E8a317" : answers[currentQuestion] ? "#2a9df4" : "#32b4a6",
                                        color: status[currentQuestion] ? "#ffffff" : answers[currentQuestion] ? "#ffffff" : "#000000",
                                    }}
                                    onClick={() => this.setState({ hideQuestions: false })}>
                                    {currentQuestion + 1}
                                </div>
                                :
                                <div className="navbar-questions">
                                    {mobileMode ? <div className="navbar-close" onClick={() => this.setState({ hideQuestions: true })}>x</div>
                                        : null}
                                    <div className="navbar-question-button-group">
                                        {questions.length > 0 ?
                                            questions.map((value, index) => {
                                                return (
                                                    <button
                                                        id={index}
                                                        key={index}
                                                        style={{
                                                            backgroundColor: status[index] ? "#E8a317" : answers[index] !== "-" ? "#2a9df4" : "#f1f1f1",
                                                            borderColor: currentQuestion === index ? "#000000" : "transparent",
                                                            color: status[index] ? "#ffffff" : answers[index] !== "-" ? "#ffffff" : "#000000",
                                                        }}
                                                        onClick={() => this.setState({ currentQuestion: index, hideQuestions: true })}
                                                    >
                                                        {parseInt(startNumber) + parseInt(index) + 1}
                                                    </button>
                                                )
                                            })
                                            : null}
                                    </div>
                                </div>
                            }
                            {!mobileMode ?
                                <div className="navbar-submit" onClick={this.handleSubmit}>
                                    <img alt="Submit" src={require('../../assets/images/ic_checklist.png')} />
                                    <label className={mobileMode ? "hidden" : "navbar-submit-label"}>SELESAI</label>
                                </div> : null
                            }

                        </div>
                        <div className="content-tryout-container">
                            <div className="content-tryout-view">
                                {questions.length > 0 ?
                                    <div className="content-question">
                                        {questionText ?
                                            <img alt="soal" src={`${questionText}`} />
                                            : null}
                                        <img alt="soal" src={`${questionImg}`} />

                                    </div>
                                    : null}
                                <div className="content-answers">
                                    <div className="content-answers-button-group">
                                        {questions.length > 0 ?
                                            questionOption ?
                                                ['A', 'B', 'C', 'D', 'E'].map((value, index) => {
                                                    return <button
                                                        id={value}
                                                        value={value}
                                                        key={index}
                                                        style={{
                                                            backgroundColor: answers[currentQuestion] === value ?
                                                                "#32b4a6" : "#ffffff",
                                                            color: answers[currentQuestion] === value ?
                                                                "#ffffff" : "#000000",
                                                        }}
                                                        onClick={(e) => this.handleAnswers(e)}>{value}</button>
                                                }) :
                                                ['A', 'B', 'C', 'D'].map((value, index) => {
                                                    return <button
                                                        id={value}
                                                        value={value}
                                                        key={index}
                                                        style={{
                                                            backgroundColor: answers[currentQuestion] === value ?
                                                                "#32b4a6" : "#ffffff",
                                                            color: answers[currentQuestion] === value ?
                                                                "#ffffff" : "#000000",
                                                        }}
                                                        onClick={(e) => this.handleAnswers(e)}>{value}</button>
                                                }) : null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="content-navigator">
                                <button className="prev" onClick={this.handlePrev}>SEBELUMNYA</button>
                                {/* <button
                        className="mark"
                        onClick={(e) => this.handleMark(e)}
                        style={{
                            backgroundColor: status[currentQuestion] ? "#E8a317" : "#000000"
                        }}
                    >
                        TANDAI RAGU
                        </button> */}
                                <button
                                    className="next"
                                    onClick={this.handleNext}
                                >SELANJUTNYA</button>
                            </div>
                        </div>
                        {showModal ?
                            <div className="modal-dashboard">
                                {loading ?
                                    <ModalField
                                        label="Memulai tryout.."
                                        showButton={false}
                                    /> :
                                    startedTryout && !finishedTryout && this.props.tryout.time_left >= 60 ?
                                        <ModalField
                                            label="Mohon tunggu, tryout akan segera dimulai.."
                                            showButton={false}
                                        />
                                        :
                                        changePack ?
                                            <ModalField
                                                label="Mohon menunggu 1 menit untuk menuju subtes berikutnya"
                                                showButton={false}
                                            />
                                            : null
                                }
                                {finishedTryout ?
                                    waitTime ?
                                        waiting ?
                                            <ModalField
                                                label="Mohon tunggu hingga waktu tryout selesai."
                                                showButton={false}
                                            />
                                            :
                                            <ModalField
                                                label="Apa Anda yakin? Anda tetap harus menunggu waktu tryout selesai."
                                                showButton={true}
                                                buttonFunctions={[() => this.setState({ waiting: true }), () => this.setState({ waitTime: false, finishedTryout: false, showModal: false })]}
                                                buttonLabels={["OK", "Batal"]}
                                                buttonColors={["#2a9df4", "#c21807"]}
                                                buttonLabelColors={["#ffffff", "#ffffff"]}
                                            />
                                        :
                                        <ModalField
                                            label="Terima kasih telah mengikuti Tryout Iwa K"
                                            showButton={true}
                                            buttonFunctions={[() => {
                                                // event.preventDefault();
                                                this.props.history.replace("/dashboard");
                                                window.location.reload();
                                            }]}
                                            buttonLabels={["OK"]}
                                            buttonColors={["#2a9df4"]}
                                            buttonLabelColors={["#ffffff"]}
                                        />
                                    : null
                                }
                            </div> : null
                        }
                        {this.props.tryout.error_messages.toString().includes("Network Error") ? <div className="offline">OFFLINE</div> : null}
                    </div>
                    : <div className="parent-container-dashboard">
                        {hideNavbar ?
                            <div className="navbar-button" onClick={() => this.setState({ hideNavbar: false, closeNavbar: true })}>
                                <img className="navbar-button-image" alt="nav-button" src={require('../../assets/images/ic_menu-button.png')} />
                            </div> :
                            <div className="navbar-dashboard">
                                {closeNavbar ?
                                    <div className="navbar-close" onClick={() => this.setState({ hideNavbar: true, closeNavbar: false })}>x</div>
                                    : null}
                                <div className="navbar-profile">
                                    <img alt="profile-img" src={require('../../assets/images/ic_ava.png')} />
                                    <label style={{ textTransform: "uppercase" }}>{username}</label>
                                    <label style={{ fontSize: "small" }}>{noinduk}</label>
                                    <label style={{ fontSize: "small" }}>{sekolah}</label>
                                </div>
                                <div className="navbar-menu">
                                    <div className='navbar-menu-button'>
                                        <ButtonField
                                            label='Cara Pengerjaan'
                                            labelColor='#000000'
                                            backgroundColor='#ffffff'
                                            onClick={() => this.props.history.replace("/tryout")}
                                        />
                                    </div>
                                    <div className="navbar-menu-button">
                                        <ButtonField
                                            label='Logout'
                                            labelColor='#ffffff'
                                            backgroundColor='#c21807'
                                        // onClick={() => this.handleLogout}
                                        />
                                    </div>
                                </div>
                            </div>}
                        <div className="content-dashboard">
                            <div className="content-title">
                                Selamat Datang {username}
                            </div>
                            <div className="content-information">
                                <label className="title">Informasi</label>
                                <label className="subtitle">Silahkan pilih tes yang tersedia</label>
                            </div>
                            <div className="content-view">
                                <div className="content-tryout" id="content-tryout">
                                    <div className="content-header">
                                        <label>Daftar Try Out yang tersedia</label>
                                    </div>
                                    {loading ?
                                        <label style={{ color: "#ffffff", alignSelf: "center" }}>Memuat..</label>
                                        :
                                        <table className="table-container">
                                            <thead>
                                                <tr className="table-header">
                                                    <th>No.</th>
                                                    <th>Nama Tes</th>
                                                    <th>Tanggal Tes</th>
                                                    <th>Status</th>
                                                    <th>Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="table-item">
                                                    <td>1</td>
                                                    <td>Tryout IWA K</td>
                                                    <td>{todayDate}</td>
                                                    <td>TryOut</td>
                                                    <td><button className="table-action" onClick={() => {
                                                        this.setState({ showModal: true, stepIndex: 4 })
                                                    }}>
                                                        Kerjakan</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    }
                                </div>
                            </div>
                        </div>
                        {showModal ?
                            <div className="modal-dashboard">
                                {startTryout ?
                                    <div className="modal-container">
                                        <label>Memuat Tryout..</label>
                                    </div> :
                                    <div className="modal-container">
                                        <label style={{ color: errorMessage ? 'red' : '' }}>{errorMessage ? errorMessage : "Silahkan isi pin untuk melanjutkan"}</label>
                                        <div className="modal-input-field">
                                            <InputField
                                                id="pin"
                                                type="numeric"
                                                enabled={true}
                                                value={pin}
                                                placeholder="Masukkan pin disini"
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        <div className="modal-button-group">
                                            <div id="button-start">
                                                <ButtonField
                                                    label={loadingTryout ? "Memuat" : "OK"}
                                                    labelColor='#ffffff'
                                                    backgroundColor={loadingTryout ? "#a9a9a9" : '#2a9df4'}
                                                    onClick={() => this.setState({ showTryout: true, stepIndex: 6 })}
                                                />
                                            </div>
                                            <div>
                                                <ButtonField
                                                    label='Batal'
                                                    labelColor='#ffffff'
                                                    backgroundColor='#c21807'
                                                    onClick={() => this.setState({ showModal: false, errorMessage: '' })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div> : null
                        }
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        tryout: state.tryout,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        GetUserInfo: () => dispatch(requests.auth.getUserInfo()),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tutorial))
