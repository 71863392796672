import React, { Component } from 'react';
import Joyride from 'react-joyride';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ButtonField } from '../../components/ButtonField';
import InputField from '../../components/InputField';
import { logout } from '../../redux/actions/authActions';
import requests from '../../redux/requests';
import { GetTodayDate } from './actions';
import './index.css';

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hideNavbar: false,
            closeNavbar: false,
            showModal: false,
            listTryout: [],
            listSusulan: [],
            selectedTryout: null,
            tryoutId: '',
            pin: '',
            loading: true,
            startTryout: false,
            errorMessage: '',
            loadingTryout: false,
            run: false,
            steps: [
                {
                    content: <h2>Let's begin our journey!</h2>,
                    locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
                    placement: 'center',
                    target: 'body',
                },
                {
                    content: <h2>Sticky elements</h2>,
                    floaterProps: {
                        disableAnimation: true,
                    },
                    spotlightPadding: 20,
                    target: '.content-tryout',
                },
                {
                    content: 'These are our super awesome projects!',
                    placement: 'bottom',
                    styles: {
                        options: {
                            width: 300,
                        },
                    },
                    target: '.navbar-profile',
                    title: 'Our projects',
                },
                {
                    content: (
                        <div>
                            You can render anything!
                            <br />
                            <h3>Like this H3 title</h3>
                        </div>
                    ),
                    placement: 'top',
                    target: '.button-container',
                    title: 'Our Mission',
                },
                {
                    content: (
                        <div>
                            <h3>All about us</h3>
                            <svg
                                width="50px"
                                height="50px"
                                viewBox="0 0 96 96"
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="xMidYMid"
                            >
                                <g>
                                    <path
                                        d="M83.2922435,72.3864207 C69.5357835,69.2103145 56.7313553,66.4262214 62.9315626,54.7138297 C81.812194,19.0646376 67.93573,0 48.0030634,0 C27.6743835,0 14.1459311,19.796662 33.0745641,54.7138297 C39.4627778,66.4942237 26.1743334,69.2783168 12.7138832,72.3864207 C0.421472164,75.2265157 -0.0385432192,81.3307198 0.0014581185,92.0030767 L0.0174586536,96.0032105 L95.9806678,96.0032105 L95.9966684,92.1270809 C96.04467,81.3747213 95.628656,75.2385161 83.2922435,72.3864207 Z"
                                        fill="#000000"
                                    />
                                </g>
                            </svg>
                        </div>
                    ),
                    placement: 'left',
                    target: 'body',
                },
            ],
        }
    }

    async componentDidMount() {
        window.addEventListener("resize", this.resize);
        this.resize();

        // if (!localStorage.getItem("type")) {
        await this.props.GetUserInfo();
        // }

        if (this.props.auth.user_info) {
            if (this.props.auth.user_info.token_tryout !== 'null' && this.props.auth.user_info.token_tryout !== "" && this.props.auth.user_info.token_tryout !== undefined && this.props.auth.user_info.token_tryout !== null && this.props.auth.user_info.token_tryout !== "undefined") {
                // this.props.history.replace("/tryout");
                this.props.history.replace("/tryout2");
            }
        }

        await this.props.GetTryout(this.props.auth.user_info.type);

        if (this.state.loading) {
            this.setState({
                listTryout: this.props.tryout.list_tryout,
                listSusulan: this.props.tryout.list_susulan,
                loading: false,
            });
        }
    }

    resize = () => {
        this.setState({
            hideNavbar: window.innerWidth <= 768 ? true : false,
            closeNavbar: false
        });
    }

    handleLogout = async () => {
        await this.props.Logout();

        this.props.history.replace('/');
    }

    handleStartTryout = async () => {
        this.setState({ loadingTryout: true });
        if (this.state.listTryout.includes(this.state.selectedTryout)) {
            await this.props.Start(this.state.tryoutId, this.state.pin, false, this.props.auth.user_info.type);
        } else {
            await this.props.Start(this.state.tryoutId, this.state.pin, true, this.props.auth.user_info.type);
        }

        await this.props.GetQuestions(localStorage.getItem("token_tryout"), this.props.auth.user_info.type);

        this.setState({ startTryout: true });

        if (localStorage.getItem("token_tryout") !== "null") {
            if (this.props.tryout.tryout_failed) {
                this.setState({ startTryout: false, errorMessage: "Pin salah!", loadingTryout: false });
            } else {
                this.props.history.replace("/tryout2")
            }
        } else {
            this.setState({ startTryout: false, errorMessage: "Anda sudah melaksanakan tryout ini!", loadingTryout: false });
        }

    }

    handleTokenExpire = () => {
        alert("Sesi habis, tolong login ulang");
        this.props.Logout();
    }

    onChange = (id, value) => {
        this.setState({ pin: value.toLowerCase() });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
    }

    render() {
        const {
            hideNavbar,
            closeNavbar,
            showModal,
            pin,
            loading,
            listTryout,
            listSusulan,
            startTryout,
            errorMessage,
            loadingTryout,
        } = this.state;
        const username = this.props.auth.user_info ? this.props.auth.user_info.nama : "Unknows";
        const noinduk = this.props.auth.user_info ? this.props.auth.user_info.noinduk : "000000";
        const sekolah = this.props.auth.user_info ? this.props.auth.user_info.sekolah : "School";
        const todayDate = GetTodayDate();
        return (
            <div className="parent-container-dashboard">
                <Joyride
                    continuous={true}
                    run={this.state.run}
                    scrollToFirstStep={true}
                    showProgress={true}
                    showSkipButton={true}
                    steps={this.state.steps}
                    styles={{
                        options: {
                            zIndex: 10000,
                        },
                    }}
                />
                {hideNavbar ?
                    <div className="navbar-button" onClick={() => this.setState({ hideNavbar: false, closeNavbar: true })}>
                        <img className="navbar-button-image" alt="nav-button" src={require('../../assets/images/ic_menu-button.png')} />
                    </div> :
                    <div className="navbar-dashboard">
                        {closeNavbar ?
                            <div className="navbar-close" onClick={() => this.setState({ hideNavbar: true, closeNavbar: false })}>x</div>
                            : null}
                        <div className="navbar-profile">
                            <img alt="profile-img" src={require('../../assets/images/ic_ava.png')} />
                            <label style={{ textTransform: "uppercase" }}>{username}</label>
                            <label style={{ fontSize: "small" }}>{noinduk}</label>
                            <label style={{ fontSize: "small" }}>{sekolah}</label>
                        </div>
                        <div className="navbar-menu">
                            <div className='navbar-menu-button'>
                                <ButtonField
                                    label='Cara Pengerjaan'
                                    labelColor='#000000'
                                    backgroundColor='#ffffff'
                                    onClick={() => { this.props.history.push("/tutorial"); console.log("GO TO TUTORIAL") }}
                                />
                            </div>
                            <div className="navbar-menu-button">
                                <ButtonField
                                    label='Logout'
                                    labelColor='#ffffff'
                                    backgroundColor='#c21807'
                                    onClick={this.handleLogout}
                                />
                            </div>
                        </div>
                    </div>}
                <div className="content-dashboard">
                    <div className="content-title">
                        Selamat Datang {username}
                    </div>
                    <div className="content-view">
                        <div className="content-tryout" style={{ backgroundColor: '#2a9df4' }}>
                            <label className="content-header">Informasi</label>
                            {/* <label className="subtitle">Silahkan pilih tes yang tersedia</label> */}
                            <table className="table-container">
                                <tbody>
                                    <tr className="table-item">
                                        <td>&#9900;</td>
                                        <td>Silahkan memilih tryout yang tersedia</td>
                                    </tr>
                                    <tr className="table-item">
                                        <td>&#9900;</td>
                                        <td>Apabila ingin berganti device saat sedang melaksanakan tryout, mohon pastikan <span style={{fontStyle: 'italic'}}>close</span> tab website ini terlebih dahulu.</td>
                                    </tr>
                                    <tr className="table-item">
                                        <td>&#9900;</td>
                                        <td>Apabila melaksanakan tryout menggunakan <span style={{fontStyle: 'italic'}}>device smartphone</span>, mohon pastikan untuk mematikan fitur <span style={{fontStyle: 'italic'}}>autolock / autosleep</span></td>
                                    </tr>
                                    <tr className="table-item">
                                        <td>&#9900;</td>
                                        <td>Diharapkan untuk tidak berpindah tab atau berpindah dari browser, tryout ini terawasi sistem</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="content-view">
                        <div className="content-tryout" id="content-tryout">
                            <div className="content-header">
                                <label>Daftar Tryout yang tersedia</label>
                            </div>
                            {loading ?
                                <label style={{ color: "#ffffff", alignSelf: "center" }}>Memuat..</label>
                                :
                                <table className="table-container">
                                    <thead>
                                        <tr className="table-header">
                                            <th>No.</th>
                                            <th>Nama Tes</th>
                                            <th>Tanggal Tes</th>
                                            <th>Status</th>
                                            <th>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listTryout.map((value, index) => {
                                            return (
                                                <tr className="table-item" key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{value.name}</td>
                                                    <td>{todayDate}</td>
                                                    <td>TryOut</td>
                                                    <td><button className="table-action" onClick={() => {
                                                        this.setState({ showModal: true, tryoutId: value.id, selectedTryout: value })
                                                        console.log("id tryout: " + value.id)
                                                    }}>
                                                        Kerjakan</button></td>
                                                </tr>
                                            )
                                        })}
                                        {listSusulan.map((value, index) => {
                                            return (
                                                <tr className="table-item" key={index}>
                                                    <td>{listTryout.length + index + 1}</td>
                                                    <td>{value.name}</td>
                                                    <td>{todayDate}</td>
                                                    <td>Susulan</td>
                                                    <td><button className="table-action"
                                                        onClick={() =>
                                                            this.setState({ showModal: true, tryoutId: value.id, selectedTryout: value })}>
                                                        Kerjakan</button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                </div>
                {showModal ?
                    <div className="modal-dashboard">
                        {startTryout ?
                            <div className="modal-container">
                                <label>Memuat Tryout..</label>
                            </div> :
                            <div className="modal-container">
                                <label style={{ color: errorMessage ? 'red' : '' }}>{errorMessage ? errorMessage : "Silahkan isi pin untuk melanjutkan"}</label>
                                <div className="modal-input-field">
                                    <InputField
                                        id="pin"
                                        type="numeric"
                                        enabled={true}
                                        value={pin}
                                        placeholder="Masukkan pin disini"
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="modal-button-group">
                                    <div>
                                        <ButtonField
                                            label={loadingTryout ? "Memuat" : "OK"}
                                            labelColor='#ffffff'
                                            backgroundColor={loadingTryout ? "#a9a9a9" : '#2a9df4'}
                                            onClick={loadingTryout ? () => alert("sedang memuat") : this.handleStartTryout}
                                        />
                                    </div>
                                    <div>
                                        <ButtonField
                                            label='Batal'
                                            labelColor='#ffffff'
                                            backgroundColor='#c21807'
                                            onClick={() => this.setState({ showModal: false, errorMessage: '' })}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </div> : null
                }
                {this.props.auth.error_message.toString().includes("Network Error") ? <div className="offline">OFFLINE</div> : null}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        tryout: state.tryout,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        Logout: () => dispatch(logout()),
        GetUserInfo: () => dispatch(requests.auth.getUserInfo()),
        GetTryout: (type) => dispatch(requests.tryout.getListTryout(type)),
        Start: (id, pin, flag, type) => dispatch(requests.tryout.startTryout(id, pin, flag, type)),
        GetQuestions: (token, type) => dispatch(requests.tryout.getTryout(token, type)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard))
