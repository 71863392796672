import React from "react";
import "./index.css";

const NavbarTryout = (props) => {
  const {
    mobileMode,
    hideQuestions,
    questions,
    answers,
    numbers,
    currentQuestion,
    username,
    noinduk,
    sekolah,
    onHideQuestions,
    onSelectQuestion,
    onSubmit,
  } = props;
  
  return (
    <div className="navbar-tryout">
      {!mobileMode ? (
        <NavbarProfile
          username={username}
          noinduk={noinduk}
          sekolah={sekolah}
        />
      ) : null}
      {mobileMode && hideQuestions ? null : (
        <NavbarQuestion
          questions={questions}
          mobileMode={mobileMode}
          currentQuestion={currentQuestion}
          answers={answers}
          numbers={numbers}
          onNavbarQuestionClick={() => console.log("mau testing")}
          onClose={onHideQuestions}
          onSelectQuestion={onSelectQuestion}
        />
      )}
      {!mobileMode ? (
        <div className="navbar-submit" onClick={onSubmit}>
          <img
            alt="Submit"
            src={require("../../assets/images/ic_checklist.png")}
          />
          <label className={mobileMode ? "hidden" : "navbar-submit-label"}>
            SELESAI
          </label>
        </div>
      ) : null}
    </div>
  );
};

const NavbarProfile = (props) => {
  const { username, noinduk, sekolah } = props;
  return (
    <div className="navbar-profile-tryout">
      <img alt="profile-img" src={require("../../assets/images/ic_ava.png")} />
      <label style={{ textTransform: "uppercase" }}>{username}</label>
      <label style={{ fontSize: "small" }}>{noinduk}</label>
      <label style={{ fontSize: "small" }}>{sekolah}</label>
    </div>
  );
};

// const NavbarQuestionMobile = (props) => {
//     const { flag, answer, currentQuestion } = props
//     return (
//         <div
//             className="navbar-questions-mobile"
//             style={{
//                 backgroundColor: flag ? "#E8a317" : answer ? "#2a9df4" : "#ffffff",
//                 borderColor: flag ? "#E8a317" : answer ? "#2a9df4" : "#32b4a6",
//                 color: flag ? "#ffffff" : answer ? "#ffffff" : "#000000",
//             }}
//         >
//             {currentQuestion}
//         </div>
//     )
// }

const NavbarQuestion = (props) => {
  const {
    flag,
    answers,
    mobileMode,
    questions,
    currentQuestion,
    numbers,
    onSelectQuestion,
    onClose,
  } = props;

  return (
    <div className="navbar-questions">
      <div className="navbar-question-button-group">
        {mobileMode ? (
          <div className="navbar-to-close" onClick={onClose}>
            x
          </div>
        ) : null}
        {questions.length > 0
          ? questions.map((value, index) => {
              let number =
                numbers.length > 0 ? parseInt(numbers[index]) + 1 : 0;

              return (
                <button
                  id={index}
                  key={index}
                  style={{
                    backgroundColor: flag
                      ? "#E8a317"
                      : answers[index] !== "-" && !answers[index]?.includes("N")
                      ? "#2a9df4"
                      : "#f1f1f1",
                    borderColor:
                      currentQuestion === index ? "#000000" : "transparent",
                    color: flag
                      ? "#ffffff"
                      : answers[index] !== "-" && !answers[index]?.includes("N")
                      ? "#ffffff"
                      : "#000000",
                  }}
                  onClick={() => onSelectQuestion(index)}
                >
                  {number.toString()}
                </button>
              );
            })
          : null}
      </div>
    </div>
  );
};

NavbarProfile.defaultProps = {
  username: "Fulan",
  noinduk: "000",
  sekolah: "Bumi",
};

// NavbarQuestionMobile.defaultProps = {
//     flag: false,
//     answer: null,
//     currentQuestion: 0
// }

NavbarQuestion.defaultProps = {
  flag: false,
  answer: null,
  mobileMode: false,
  currentQuestion: 0,
  questions: [],
  numbers: [],
  onNavbarQuestionClick: () => console.log("click navbar question"),
  onClose: () => console.log("click close navbar questions"),
};

export default NavbarTryout;
