const tryout = requests => ({
    getListTryout: (type) => {
        return async dispatch => {
            await requests
                .get(`/${type}/`)
                .then(res => {
                    dispatch({ type: "GET_LIST_TRYOUT_SUCCESS", res });
                })
                .catch(err => {
                    dispatch({ type: "GET_LIST_TRYOUT_ERROR", err });
                })
        }
    },

    startTryout: (id, pin, flag, type) => {
        const data = {
            id: id,
            pin: pin
        }
        return async dispatch => {
            await requests
                .post(`/${type}/`, data, { susulan: flag })
                .then(res => {
                    console.log(res);
                    dispatch({ type: "START_TRYOUT_SUCCESS", res });
                })
                .catch(err => {
                    console.log(err);
                    dispatch({ type: "START_TRYOUT_FAILED", err });
                })
        }
    },

    getTryout: (tokenTryout, type) => {
        return async dispatch => {
            await requests
                .get(`/session/${type}/?token=${tokenTryout}`)
                .then(res => {
                    dispatch({ type: "GET_TRYOUT_SUCCESS", res });
                })
                .catch(err => {
                    dispatch({ type: "GET_TRYOUT_FAILED", err });
                })
        }
    },

    getTryoutAnswer: (tryoutId, answerId, topic, type, order) => {
        return async dispatch => {
            await requests
                .get(`/question/${type}/${tryoutId}/${topic}/?jawaban=${answerId}&order=${order}`)
                .then(res => {
                    dispatch({ type: "GET_TRYOUT_ANSWER_SUCCESS", res });
                })
                .catch(err => {
                    dispatch({ type: "GET_TRYOUT_ANSWER_FAILED", err });
                })
        }
    },

    postTryout: (props) => {
        const {tryoutId, answerId, topic, answers, order, type} = props;
        const dataToPost = {
            id: answerId,
            jawaban: answers,
            order
        }
        console.log(props, dataToPost);
        return dispatch => {
            requests
                .post(`/question/${type}/${tryoutId}/${topic}/`, dataToPost)
                .then(res => {
                    dispatch({ type: "POST_TRYOUT_SUCCESS", res });
                })
                .catch(err => {
                    dispatch({ type: "POST_TRYOUT_FAILED", err });
                })
        }
    }
})

export default tryout;
