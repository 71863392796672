const SetCurrentPack = (context) => {
    let currentPack = localStorage.getItem("current_pack") ?
        localStorage.getItem("current_pack") :
        context.state.currentPack;

    // first time
    if (currentPack === '') {
        currentPack = "tps";
    }

    localStorage.setItem("current_pack", currentPack);
    console.log("currentPack: " + currentPack);
    return currentPack;
}

const GetCurrentPack = () => {
    return localStorage.getItem("current_pack");
}

const SetCurrentTopic = (context) => {
    let currentPack = localStorage.getItem("current_pack") ?
        localStorage.getItem("current_pack") :
        context.state.currentPack;
    let currentTopic = localStorage.getItem("current_topic") ?
        localStorage.getItem("current_topic") :
        context.state.currentTopic;

    if (currentPack === "tps") {
        if (currentTopic === "") {
            currentTopic = 0;
        }
        else if (parseInt(currentTopic) === 3) {
            currentTopic = 0;
            currentPack = "tka";
        }
        else {
            currentTopic = parseInt(currentTopic) + 1;
        }
    }
    else if (currentPack === "tka") {
        if (currentTopic === "") {
            currentTopic = 0;
        }
        else if (parseInt(currentTopic) === 3) {
            currentTopic = 0
        }
        else {
            currentTopic = parseInt(currentTopic) + 1;
        }
    }
    else {
        console.log("Current Packet not found");
    }

    localStorage.setItem("current_pack", currentPack);
    localStorage.setItem("current_topic", currentTopic);
    console.log("currentTopic" + currentTopic);
    return currentTopic;
}

const GetCurrentTopic = () => {
    return localStorage.getItem("current_topic");
}

const GetTkaSettings = () => {
    return JSON.parse(localStorage.getItem("tka_settings"));
}

const GetTpsSettings = () => {
    return JSON.parse(localStorage.getItem("tps_settings"));
}

const GetAnswerId = () => {
    return localStorage.getItem("id_answer");
}

// const SetAnswerId = (answerId) => {
//     localStorage.setItem("id_answer", answerId);
// }

const MinuteToSeconds = (time) => {
    return time * 60;
}

const GetTopicLabel = (context) => {
    const currentPack = GetCurrentPack();
    const currentTopic = GetCurrentTopic();
    var topicLabel = '';

    if (currentPack === "tps") {
        topicLabel = context.props.tryout.tps_order[currentTopic];
    }
    else if (currentPack === "tka") {
        topicLabel = context.props.tryout.tka_order[currentTopic];
    }
    else {
        return '';
    }

    topicLabel = topicLabel.replace(/_/g, " ").toUpperCase();
    return topicLabel;
}

const SetTryoutDuration = (context) => {
    const currentPack = GetCurrentPack();
    const currentTopic = GetCurrentTopic();

    const tkaSettings = GetTkaSettings();
    const tpsSettings = GetTpsSettings();
    const tkaOrder = context.props.tryout.tka_order;
    const tpsOrder = context.props.tryout.tps_order;
    console.log(currentTopic);
    console.log(currentPack);

    let duration = 0;

    if (currentPack === "tps") {
        let _currentTopic = tpsOrder[currentTopic];
        duration = tpsSettings[_currentTopic].durasi;
    }
    else if (currentPack === "tka") {
        let _currentTopic = tkaOrder[currentTopic];
        duration = tkaSettings[_currentTopic].durasi;
    }
    else {
        console.log("Current Packet not found");
    }

    var seconds = MinuteToSeconds(duration);
    var time = TimeToString(seconds);

    localStorage.setItem("duration", seconds);

    return time;
}

const GetTimeLeft = () => {
    const duration = parseInt(localStorage.getItem("duration"));
    const startTime = parseInt(localStorage.getItem("start_time"));

    var time = new Date();
    var now = time.getTime();
    const timePassed = Math.round((now - startTime) / 1000);
    const timeLeft = duration - timePassed;

    console.log("timeleft: " + timeLeft)

    return timeLeft;
}

const TimeToString = (seconds) => {
    var minutes = Math.floor((seconds % 3600) / 60);
    var sec = seconds % 60;

    var stringMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    var stringSeconds = sec < 10 ? `0${sec}` : `${sec}`;

    var time = `${stringMinutes}:${stringSeconds}`


    return time;
}

const GetCurrentQuestion = async (context, currentPack, currentTopic) => {
    var settings = '';
    var topic = '';
    var id = '';

    if (currentPack === "tka") {
        settings = GetTkaSettings();
        topic = context.props.tryout.tka_order;
    }
    else {
        settings = GetTpsSettings();
        topic = context.props.tryout.tps_order;
    }

    topic = topic[currentTopic];

    id = settings[topic].id;
    await context.props.GetQuestions(id, topic);

    console.log(context.props.tryout.current_questions);

    return context.props.tryout.current_questions;
}

const GetArrayFromLocal = (name) => {
    const json = JSON.parse(localStorage.getItem(name));
    const array = Object.values(json);

    return array;
}

const SaveStartTime = () => {
    var time = new Date();
    var now = time.getTime();
    localStorage.setItem("start_time", now);
}

const Countdown = (context, duration, display, role) => {
    var timer = duration, minutes, seconds;
    var interval = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? `0${minutes}` : minutes;
        seconds = seconds < 10 ? `0${seconds}` : seconds;

        display.innerHTML = `${minutes}:${seconds}`;

        if (--timer < 0) {
            if (role === "main") {
                context.init();
            } else if (role === "sub") {
                context.setState({ changePack: false, showModal: false, waitTime: 0, finishedTryout: false });
                context.init();
                console.log("End of time");
            }
            clearInterval(interval);
        }
    }, 1000);
}

const SaveArrayToLocal = (array, name) => {
    const objectArray = Object.assign({}, array);
    const jsonString = JSON.stringify(objectArray);

    localStorage.setItem(name, jsonString);
}

// const SubmitCurrentTopic = async (context) => {
//     const currentPack = context.state.currentPack;
//     const currentTopic = parseInt(context.state.currentTopic);

//     let answerId = GetAnswerId();
//     let answers = localStorage.getItem("answers");
//     var tryoutId = '';
//     var settings = '';

//     const tkaOrder = context.props.tryout.tka_order;
//     const tpsOrder = context.props.tryout.tps_order;

//     var topicToSubmit = '';
//     var changePack = '';
//     var quitTryout = '';

//     if (currentPack === "tps") {
//         settings = GetTpsSettings();
//         topicToSubmit = tpsOrder[currentTopic];
//         if (currentTopic === 3) {
//             changePack = true;
//         }
//     } else {
//         settings = GetTkaSettings();
//         topicToSubmit = tkaOrder[currentTopic];
//         if (currentTopic === 3) {
//             quitTryout = true;
//         }
//     }

//     tryoutId = settings[topicToSubmit].id;

//     await context.props.Submit(tryoutId, answerId, topicToSubmit, answers);

//     answers = [];
//     let marks = [];

//     SaveArrayToLocal(answers, "answers");
//     SaveArrayToLocal(marks, "marks");
//     localStorage.setItem("duration", 0);
//     localStorage.setItem("start_time", 0);
//     localStorage.setItem("wait_time", 0);

//     if (quitTryout) {
//         localStorage.removeItem("tka_settings");
//         localStorage.removeItem("tps_settings");
//         localStorage.removeItem("id_answer");
//         localStorage.removeItem("current_pack");
//         localStorage.removeItem("current_topic");
//         localStorage.removeItem("start_time");
//         localStorage.removeItem("wait_time");
//         localStorage.removeItem("duration");
//         localStorage.removeItem("answers");
//         localStorage.removeItem("marks");
//         localStorage.removeItem("init");
//         localStorage.removeItem("started_tryout");
//         alert("Tryout selesai, terima kasih");
//         context.props.history.replace("/dashboard");
//     } else {
//         if (changePack) {
//             context.waitBeforeSwitch();
//         } else {
//             context.init();
//         }
//     }
// }

const ClearSession = () => {
    localStorage.removeItem("current_topic");
    localStorage.removeItem("answers");
    localStorage.removeItem("questions");
    localStorage.removeItem("token_tryout");
    localStorage.removeItem("id_tryout");
    localStorage.removeItem("id_answer");
    localStorage.removeItem("current_topic");
    localStorage.removeItem("start_number");
    localStorage.removeItem("nomor");
    localStorage.removeItem("start_time");
    localStorage.removeItem("numbers");
}

export {
    SetCurrentPack,
    SetCurrentTopic,
    GetCurrentPack,
    GetCurrentTopic,
    SetTryoutDuration,
    GetTopicLabel,
    GetCurrentQuestion,
    SaveStartTime,
    GetAnswerId,
    Countdown,
    GetTimeLeft,
    TimeToString,
    SaveArrayToLocal,
    GetArrayFromLocal,
    // SubmitCurrentTopic,
    ClearSession,
}