import React, { Component } from 'react'
import InputField from '../../components/InputField'
import './index.css'
import { withRouter } from 'react-router-dom'
import { ButtonField } from '../../components/ButtonField';
import { connect } from 'react-redux';
import requests from '../../redux/requests';
import { logout } from '../../redux/actions/authActions';
import { isVerified } from './action';
import ModalField from '../../components/ModalField';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            noinduk: '',
            password: '',
            showPassword: false,
            loginError: false,
            showModal: false,
        };
    }

    onChange = (id, value) => {
        switch (id) {
            case 'noinduk':
                this.setState({ noinduk: value })
                break;
            case 'password':
                this.setState({ password: value })
                break;
            default:
                break;
        }
    }

    handleLogin = async () => {
        if (this.state.noinduk === "" || this.state.password === "") {
            alert("Mohon lengkapi nomor induk dan password.");
        } else {
            console.log("Logging in..");
            this.setState({showModal: true});
            await this.props.Login(this.state.noinduk, this.state.password);
            console.log("Logged in ");
            if (this.props.auth.is_logged_in) {
                if (await isVerified(this)) {
                    this.props.history.replace('/dashboard');
                } else {
                    this.setState({ loginError: true, showModal: false });
                    this.props.Logout();
                }
                // this.props.history.replace('/dashboard');
            } else {
                this.setState({ loginError: true, showModal : false });
            }
        }
    }

    render() {
        const { showPassword, showModal } = this.state;
        return (
            <div className='parent-container'>
                <div className='form-container'>
                    <div className='logo-container'>
                        <img className='logo-style' alt='logo' src={require('../../assets/images/logo-transparent.png')} />
                    </div>
                    <div className='input-container'>
                        <label>Nomor Induk</label>
                        <InputField
                            id="noinduk"
                            type='numeric'
                            enabled={true}
                            value={this.state.noinduk}
                            placeholder="Masukkan nomor induk kamu"
                            onChange={this.onChange}
                        />
                    </div>
                    <div className='input-container'>
                        <div className='label-password-container'>
                            <label>Password</label>
                            <label className='label-show-password' onClick={() => this.setState({ showPassword: !this.state.showPassword })}>{showPassword ? 'Sembunyikan' : 'Tampilkan'}</label>
                        </div>
                        <InputField
                            id="password"
                            type={showPassword ? 'text' : 'password'}
                            enabled={true}
                            value={this.state.password}
                            placeholder="Masukkan password kamu"
                            onChange={this.onChange}
                        />
                        {this.state.loginError ? <label style={{ color: 'red' }}>No. Induk atau password salah!</label> : null}
                    </div>
                    <div className='button-container-form'>
                        <ButtonField
                            label='Masuk'
                            labelColor='#ffffff'
                            onClick={this.handleLogin}
                        />
                    </div>
                    <div className='button-container-form'>
                        <ButtonField
                            label='Belum Daftar'
                            labelColor='#32B4A6'
                            backgroundColor='#ffffff'
                            onClick={() => alert("Silahkan hubungi admin bimbel untuk mendaftar")}
                        />
                    </div>
                </div>
                {showModal ?
                <div className="modal-dashboard">
                    <ModalField
                        label="Mohon tunggu, sedang login.."
                        showButton={false} /> </div>
                    : null}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = dispatch => {
    return {
        Login: (noinduk, password) => dispatch(requests.auth.login(noinduk, password)),
        GetUserInfo: () => dispatch(requests.auth.getUserInfo()),
        Logout: () => dispatch(logout()),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
