const auth = requests => ({
    login: (username, password) => {
        const userData = {
            no_induk: username,
            password: password,
        }
        return async dispatch => {
            await requests
                .post("/auth/login/", userData)
                .then(res => {
                    dispatch({ type: "LOGIN_SUCCESS", res });
                })
                .catch(err => {
                    dispatch({ type: "LOGIN_FAILED", err });
                })
            // let res = "token";
            // let err = "error";
            // if(username === "admin" && password==="admin") {
            //     dispatch({ type: "LOGIN_SUCCESS", res });
            // } else {
            //     dispatch({ type: "LOGIN_FAILED", err });
            // }
        }
    },

    getUserInfo: () => {
        return async dispatch => {
            await requests
                .get("/user/")
                .then(res => {
                    dispatch({ type: "GET_USER_INFO_SUCCESS", res});
                })
                .catch(err => {
                    dispatch({type: "GET_USER_INFO_FAILED", err});
                })
        }
    },
})

export const logout = () => {
    console.log("Logging out")
    return (dispatch) => {
        dispatch({ type: "LOGOUT_SUCCESS" });
    }
}

export default auth;