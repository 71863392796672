import React from 'react'
import PropTypes from 'prop-types';
import './index.css'

export default class InputField extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    enabled: PropTypes.bool,
    focussed: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    enabled: true,
    focussed: false,
    type: 'text',
    value: '',
    placeholder: '',
    onChange: () => '',
  };

  constructor(props) {
    super(props);

    this.state = {
      focussed: (props.enabled && props.focussed) || false,
      value: props.value || '',
      placeholder: props.placeholder || '',
    };
  }

  onChange = event => {
    const { id } = this.props;
    const value = event.target.value;
    this.setState({ value });
    return this.props.onChange(id, value);
  }

  render() {
    const { focussed, value, placeholder } = this.state;
    const { id, type, enabled } = this.props;

    const fieldClassName = `field ${enabled ? focussed ? 'focussed' : '' : 'disabled'}`
    return (
      <div className={fieldClassName}>
        <input
          id={id}
          type={type}
          disabled={!enabled}
          value={value}
          placeholder={placeholder}
          onChange={(e) => this.onChange(e)}
          onFocus={() => enabled && this.setState({ focussed: true })}
          onBlur={() => enabled && this.setState({ focussed: false })}
        />
      </div>
    )
  }
}