const GetListTryout = async (context) => {
    const dataFromApi = await context.props.GetTryout();
    let newData = dataFromApi ? [dataFromApi.tryout, dataFromApi.susulan] : ['no', 'no'];
    console.log({newData})
    return newData;
}

const SaveToLocal = (name, data) => {
    localStorage.setItem(name, data);
}

const GetTodayDate = () => {
    let today = new Date();
    let dd = String(today.getDate());
    let mm = String(today.getMonth() + 1);
    let yyyy = today.getFullYear();

    return dd + "/" + mm + "/" + yyyy;
}

export {GetTodayDate,  GetListTryout, SaveToLocal}