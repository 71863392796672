import requests from "../requests";

const initState = {
    auth_error: "",
    is_logged_in: false,
    user_info: null,
    error_message: "",
}

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case "LOGIN_SUCCESS":
            console.log(action.type);
            requests.setToken(action.res.data.token);
            return {
                ...state,
                is_logged_in: true,
                error_message: "",
            }

        case "LOGIN_FAILED":
            console.log(action.type);
            return {
                ...state,
                auth_error: action.err.message,
            }

        case "LOGOUT_SUCCESS":
            console.log("logged out");
            localStorage.removeItem("type");
            requests.unsetToken();
            return {
                ...state,
                is_logged_in: false,
                user_info: null,
                error_message: "",
            }

        case "GET_USER_INFO_SUCCESS":
            console.log(action.type);
            console.log(action.res);
            localStorage.setItem("type", action.res.type);
            return {
                ...state,
                user_info: action.res,
                error_message: "",
            }

        case "GET_USER_INFO_FAILED":
            console.log(action.type);
            console.log(action.err);
            return {
                ...state,
                error_message: action.err,
            }

        default:
            return state;
    }
};

export default authReducer;