import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'

const getCookie = (cname) => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function RouteWrapper({
    component: Component,
    isPrivate,
    ...rest
}) {
    var signed = false;

    if (getCookie("token") !== "") {
        signed = true;
    } else {
        signed = false;
    }

    // Redirect user to Login page if he tries to access a private route
    // without authentication
    if (isPrivate && !signed) {
        alert("Please Login");
        return <Redirect to="/" />;
    }

    // Redirect user to Dashboard if he tries to access a non private route
    // (login or register) after being authenticated
    if (!isPrivate && signed) {
        return <Redirect to="/dashboard" />;
    }

    // If not both, just send him there
    return <Route {...rest} component={Component} />;
}

RouteWrapper.propTypes = {
    isPrivate: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
}

RouteWrapper.defaultProps = {
    isPrivate: false,
}

export default RouteWrapper;