import React, { useState } from 'react'
import './index.css'

const HeaderTryout = (props) => {
    const [switchTimeLabel, setSwitchTimeLabel] = useState(false)

    const { currentQuestion, currentTopic, answers, mobileMode, onSubmit, onHideQuestions, flag, numbers } = props;
    let number = numbers.length > 0 ? parseInt(numbers[currentQuestion]) + 1 : 0;
    return (
        <div className="header-tryout">
            {mobileMode ?
                <div className="navbar-submit" onClick={onSubmit}>
                    <img alt="Submit" src={require('../../assets/images/ic_checklist.png')} />
                    <label className={mobileMode ? "hidden" : "navbar-submit-label"}>SELESAI</label>
                </div>
                : <label style={{ fontWeight: 'bold' }} >Tryout Iwa K</label>
            }
            <div className="navbar-question-number"
                style={mobileMode ? {
                    backgroundColor: flag ? "#E8a317" : answers[currentQuestion] !== "-" ? "#2a9df4" : "#f1f1f1",
                    color: flag ? "#ffffff" : answers[currentQuestion] !== "-" ? "#ffffff" : "#000000",
                } : null}
                onClick={() => onHideQuestions(false)}
                >
                {mobileMode ? null : <span style={{ fontSize: 'small' }}>Nomor Soal</span>}
                <span>{number.toString()}</span>
            </div>
            <div className="navbar-time" onClick={!mobileMode ? null : () => setSwitchTimeLabel(!switchTimeLabel)}>
                <label className={mobileMode && !switchTimeLabel ? "navbar-title hidden" : "navbar-title"}>{currentTopic}</label>
                <label id="time-label" className={mobileMode && switchTimeLabel ? "navbar-time-label hidden" : "navbar-time-label"}>{props.countdown}</label>
            </div>
        </div>
    );
}

HeaderTryout.defaultProps = {
    currentQuestion: 0,
    currentTopic: 'HADE',
    countdown: '00:00',
    mobileMode: false,
    flag: null,
    answers: [],
    numbers: [],
}

export default HeaderTryout