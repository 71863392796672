import React from 'react'
import { Switch } from 'react-router-dom'
import Route from './Route'

import Login from '../pages/Login'
import Register from '../pages/Register'
import Dashboard from '../pages/Dashboard'
import Tryout from '../pages/Tryout'
import Tutorial from '../pages/Tutorial'
import TryoutRevision from '../pages/Tryout/TryoutRevision'

export default function Routes() {
    return (
        <Switch>
            <Route exact path="/" component={Login} />
            <Route path="/register" component={Register} />

            <Route path="/dashboard" component={Dashboard} isPrivate />
            <Route path="/tryout" component={Tryout} isPrivate />
            <Route path="/tutorial" component={Tutorial} isPrivate />
            <Route path="/tryout2" component={TryoutRevision} isPrivate />

            {/*Redirecct user to Login page if user is not authenticated */}
            <Route component={Login} />
        </Switch>
    )
}
