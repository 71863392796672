import React from 'react';
import { ButtonField } from '../ButtonField';

export const ModalField = (props) => {
    const { label, showButton, buttonFunctions, buttonLabels, buttonColors, buttonLabelColors } = props;
    return (
        <div className="modal-container">
            <label>{label}</label>
            {showButton ?
                <div className="modal-button-group">
                    {buttonLabels.map((value, index) => {
                        console.log(index);
                        return (
                            <ButtonField
                                key={index}
                                label={value}
                                labelColor={buttonLabelColors[index]}
                                backgroundColor={buttonColors[index]}
                                onClick={buttonFunctions[index]}
                            />
                        )
                    })}
                </div> : null
            }
        </div>
    )
}

export default ModalField