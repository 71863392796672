import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ModalField from '../../components/ModalField';
import requests from '../../redux/requests';
import {
    ClearSession, Countdown, GetArrayFromLocal, SaveArrayToLocal, SaveStartTime
} from './action';
import './index.css';

class Tryout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mobileMode: false,
            closeQuestions: false,
            hideQuestions: false,
            startNumber: 0,
            currentQuestion: 0,
            currentPack: "",
            currentTopic: "",
            waitTime: false,
            questions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            answers: [],
            status: [],
            answerId: '',
            durationLabel: "00:00",
            topicLabel: 'TOPIC',
            loading: true,
            showModal: true,
            changePack: false,
            startedTryout: false,
            finishedTryout: false,
            waiting: false,
            quitTryout: false,
            switchTimeLabel: false,
            initialized: false,
            recovered: false,
            errorMessage: "",
        }
    }

    async componentDidMount() {
        window.addEventListener("resize", this.resize);
        window.addEventListener("focus", this.refresh);
        this.resize();

        let initialized = this.state.initialized;

        await this.props.GetUserInfo();

        if (!initialized) {
            if (this.props.auth.user_info.token_tryout === 'null' || this.props.auth.user_info.token_tryout === "" || this.props.auth.user_info.token_tryout === undefined || this.props.auth.user_info.token_tryout === null || this.props.auth.user_info.token_tryout === "undefined") {
                ClearSession();
                this.props.history.replace("/dashboard");
            } else {
                this.init();
            }
        }
    }

    refresh = () => {
        window.location.reload();
    }

    init = async () => {
        console.log("INIT()");

        var timeLeft = 0;
        var questions = localStorage.getItem("questions") !== null ? localStorage.getItem("questions") : [];
        // var answers = localStorage.getItem("answers") !== null ? GetArrayFromLocal("answers") : [];
        var idAnswer = localStorage.getItem("id_answer") !== null ? localStorage.getItem("id_answer") : "";
        var idTryout = localStorage.getItem("id_tryout") !== null ? localStorage.getItem("id_tryout") : "";
        var startNumber = localStorage.getItem("start_number") !== null ? parseInt(localStorage.getItem("start_number")) : 0;
        var startedTryout = false;
        var finishedTryout = false;
        var changePack = false;
        var showModal = false;
        var waiting = false;
        var waitTime = false;
        var stringTopic = "";
        var currentQuestion = 0;
        var currentTopic = localStorage.getItem("current_topic") !== null ? localStorage.getItem("current_topic") : "";
        var label = document.getElementById("time-label");

        // fetch answer from server
        await this.props.GetCurrentAnswer(idTryout, idAnswer, currentTopic, this.props.auth.user_info.type);
        var answers = this.props.tryout.current_answers ? this.props.current_answers : [];

        // if answers is there, then post it
        // if (localStorage.getItem("answers") !== null) {
        if (this.props.tryout.current_answers) {
            answers = this.props.tryout.current_answers;
            let stringAnswer = String(answers);
            await this.props.Submit(idTryout, idAnswer, currentTopic, stringAnswer, this.props.auth.user_info.type);
        }

        await this.props.GetQuestions(this.props.auth.user_info.token_tryout, this.props.auth.user_info.type);
        console.log("DIS IS IT: " + this.props.auth.user_info.token_tryout);

        // if topic changed
        if (currentTopic !== "" && currentTopic !== this.props.tryout.current_topic) {
            currentTopic = this.props.tryout.current_topic;
            questions = this.props.tryout.current_questions;
            startNumber = parseInt(localStorage.getItem("start_number"));
            localStorage.setItem("current_topic", currentTopic);
            localStorage.setItem("questions", Object.values(questions));
            localStorage.setItem("start_number", startNumber);

            answers = new Array(questions.length).fill("-");
            let stringAnswer = String(answers);
            // answers = this.props.tryout.current_answers !== null && this.props.tryout.current_answers !== undefined || this.props.tryout.current_answers.length > 0 ? this.props.tryout.current_answers : new Array(questions.length).fill("-");
            SaveArrayToLocal(answers, "answers");
            await this.props.Submit(idTryout, idAnswer, currentTopic, stringAnswer, this.props.auth.user_info.type);
        }

        currentTopic = this.props.tryout.current_topic !== "" ? this.props.tryout.current_topic : "";

        // check state now
        switch (currentTopic) {
            // when tryout change from TPS to TPA
            case "waiting":
                stringTopic = "Menunggu..";
                timeLeft = this.props.tryout.time_left;
                startedTryout = true;
                changePack = true;
                showModal = true;
                questions = [];

                label = document.getElementById("time-label");
                Countdown(this, timeLeft, label, "main");
                break;
            // when tryout is finished
            case "end":
                finishedTryout = true;
                startedTryout = false;
                showModal = true;
                questions = [];

                ClearSession();
                break;
            // when the user came too early
            case "":
                stringTopic = "Menunggu..";
                timeLeft = this.props.tryout.time_left;
                startedTryout = false;
                showModal = true;

                label = document.getElementById("time-label");
                Countdown(this, timeLeft, label, "main");
                break;
            // set current tryout
            default:
                stringTopic = currentTopic.replace(/_/g, " ").toUpperCase();
                timeLeft = this.props.tryout.time_left;
                questions = this.props.tryout.current_questions;
                idAnswer = this.props.tryout.id_answer;
                idTryout = this.props.tryout.id_tryout;
                startedTryout = true;

                console.log("default")
                console.log({answers});

                // handle answer if still empty, mostly on first subject
                if (!answers || answers.length === 0) {
                    console.log("topic changed");
                    answers = new Array(questions.length).fill("-");
                    SaveArrayToLocal(answers, "answers");
                    console.log("called");
                }

                // Start counter
                label = document.getElementById("time-label");
                Countdown(this, timeLeft, label, "main");
                break;
        }

        console.log("Answer now: " + answers + ", props: ");
        console.log(this.props.tryout.current_answers);

        this.setState({
            currentTopic: stringTopic,
            answerId: idAnswer,
            topicLabel: stringTopic,
            questions: questions,
            answers: answers,
            initialized: true,
            currentQuestion: currentQuestion,
            startedTryout: startedTryout,
            finishedTryout: finishedTryout,
            changePack: changePack,
            loading: false,
            showModal: showModal,
            waiting: waiting,
            waitTime: waitTime,
            startNumber,
        })
    }

    resize = () => {
        this.setState({
            mobileMode: window.innerWidth <= 768 ? true : false,
            hideQuestions: window.innerWidth <= 768 ? true : false,
        });
    }

    // waitBeforeSwitch = () => {
    //     var label = document.getElementById("time-label");
    //     Countdown(this, 60, label, "sub");
    //     this.setState({ changePack: true, showModal: true, waitTime: 60, finishedTryout: true });
    // }

    handleStartTryout = () => {
        var label = document.getElementById("time-label");
        SaveStartTime();
        Countdown(this, localStorage.getItem("duration"), label, "main");
        localStorage.setItem("started_tryout", 1);
        this.setState({ showModal: false, startedTryout: true })
    }

    handleMark = (e) => {
        var currentMark = this.state.status;
        if (currentMark[this.state.currentQuestion] === 1) {
            currentMark[this.state.currentQuestion] = 0;
        } else {
            currentMark[this.state.currentQuestion] = 1;
        }
        e.target.blur();

        this.setState({ status: currentMark });
        SaveArrayToLocal(currentMark, "marks");
    }

    handlePrev = () => {
        let prevQuestion = this.state.currentQuestion - 1;
        if (prevQuestion < 0) {
            prevQuestion = this.state.questions.length - 1;
        }

        this.setState({ currentQuestion: prevQuestion });
    }

    handleNext = () => {
        let nextQuestion = this.state.currentQuestion + 1;
        if (nextQuestion === this.state.questions.length) {
            nextQuestion = 0;
        }

        this.setState({ currentQuestion: nextQuestion });
    }

    handleAnswers = (e) => {
        var currentAnswer = this.state.answers;
        if (currentAnswer[this.state.currentQuestion] === e.target.value) {
            currentAnswer[this.state.currentQuestion] = "-";
        } else {
            currentAnswer[this.state.currentQuestion] = e.target.value;
        }
        e.target.blur();

        this.setState({ answers: currentAnswer });
        console.log({ currentAnswer });
        SaveArrayToLocal(currentAnswer, "answers");

        this.sendAnswersToServer();
    }

    sendAnswersToServer = async () => {
        var idAnswer = localStorage.getItem("id_answer") !== null ? localStorage.getItem("id_answer") : "";
        var idTryout = localStorage.getItem("id_tryout") !== null ? localStorage.getItem("id_tryout") : "";
        var currentTopic = localStorage.getItem("current_topic") !== null ? localStorage.getItem("current_topic") : "";

        var answers = GetArrayFromLocal("answers");
        let stringAnswer = String(answers);
        this.props.Submit(idTryout, idAnswer, currentTopic, stringAnswer, this.props.auth.user_info.type);
    }

    handleSubmit = () => {
        this.sendAnswersToServer();

        this.setState({
            showModal: true,
            finishedTryout: true,
            waitTime: true,
        })
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        window.removeEventListener("focus", this.refresh);
    }

    render() {
        const {
            mobileMode,
            hideQuestions,
            startNumber,
            currentQuestion,
            waitTime,
            status,
            questions,
            answers,
            durationLabel,
            topicLabel,
            switchTimeLabel,
            showModal,
            startedTryout,
            loading,
            finishedTryout,
            changePack,
            waiting,
        } = this.state;

        const questionImg = questions[currentQuestion] ? questions[currentQuestion].soal : '';
        const questionText = questions[currentQuestion] ? questions[currentQuestion].text : '';
        const questionOption = questions[currentQuestion] ? questions[currentQuestion].sampai_e : false;
        const username = this.props.auth.user_info ? this.props.auth.user_info.nama : "Fulan";
        const noinduk = this.props.auth.user_info ? this.props.auth.user_info.noinduk : "000000";
        const sekolah = this.props.auth.user_info ? this.props.auth.user_info.sekolah : "Asal Sekolah";
        return (
            <div className="parent-container-tryout">
                <div className="header-tryout">
                    {mobileMode ?
                        <div className="navbar-submit" onClick={this.handleSubmit}>
                            <img alt="Submit" src={require('../../assets/images/ic_checklist.png')} />
                            <label className={mobileMode ? "hidden" : "navbar-submit-label"}>SELESAI</label>
                        </div>
                        : <label style={{ fontWeight: "bold" }}>Tryout Iwa K</label>
                    }
                    <div
                        className="navbar-question-number"
                        style={{
                            backgroundColor: status[currentQuestion] ? "#E8a317" : answers[currentQuestion] && answers[currentQuestion] !== "-" && mobileMode ? "#2a9df4" : "#ffffff",
                            borderColor: status[currentQuestion] ? "#E8a317" : answers[currentQuestion] && answers[currentQuestion] !== "-" && mobileMode ? "#2a9df4" : "#32b4a6",
                            color: status[currentQuestion] ? "#ffffff" : answers[currentQuestion] && answers[currentQuestion] !== "-" && mobileMode ? "#ffffff" : "#000000",
                        }}
                        onClick={() => {
                            console.log("showQuestion!")
                            this.setState({ hideQuestions: false })
                        }
                        }>
                        {!mobileMode ? <span style={{ fontSize: "small" }}>Nomor Soal</span> : null}
                        {parseInt(startNumber) + parseInt(currentQuestion) + 1}
                    </div>
                    <div className="navbar-time" onClick={!mobileMode ? null : () => this.setState({ switchTimeLabel: !this.state.switchTimeLabel })}>
                        <label className={mobileMode && !switchTimeLabel ? "navbar-title hidden" : "navbar-title"}>{topicLabel}</label>
                        <label id="time-label" className={mobileMode && switchTimeLabel ? "navbar-time-label hidden" : "navbar-time-label"}>{durationLabel}</label>
                    </div>
                </div>
                <div className="navbar-tryout">
                    <div className="navbar-profile-tryout">
                        <img alt="profile-img" src={require('../../assets/images/ic_ava.png')} />
                        <label>{username}</label>
                        <label style={{ fontSize: "small" }}>{noinduk}</label>
                        <label style={{ fontSize: "small" }}>{sekolah}</label>
                    </div>
                    {mobileMode && hideQuestions ?
                        <div
                            className="navbar-questions-mobile"
                            style={{
                                backgroundColor: status[currentQuestion] ? "#E8a317" : answers[currentQuestion] ? "#2a9df4" : "#ffffff",
                                borderColor: status[currentQuestion] ? "#E8a317" : answers[currentQuestion] ? "#2a9df4" : "#32b4a6",
                                color: status[currentQuestion] ? "#ffffff" : answers[currentQuestion] ? "#ffffff" : "#000000",
                            }}
                            onClick={() => this.setState({ hideQuestions: false })}>
                            {currentQuestion + 1}
                        </div>
                        :
                        <div className="navbar-questions">
                            {mobileMode ? <div className="navbar-close" onClick={() => this.setState({ hideQuestions: true })}>x</div>
                                : null}
                            <div className="navbar-question-button-group">
                                {questions.length > 0 ?
                                    questions.map((value, index) => {
                                        return (
                                            <button
                                                id={index}
                                                key={index}
                                                style={{
                                                    backgroundColor: status[index] ? "#E8a317" : answers[index] !== "-" ? "#2a9df4" : "#f1f1f1",
                                                    borderColor: currentQuestion === index ? "#000000" : "transparent",
                                                    color: status[index] ? "#ffffff" : answers[index] !== "-" ? "#ffffff" : "#000000",
                                                }}
                                                onClick={() => this.setState({ currentQuestion: index, hideQuestions: true })}
                                            >
                                                {parseInt(startNumber) + parseInt(index) + 1}
                                            </button>
                                        )
                                    })
                                    : null}
                            </div>
                        </div>
                    }
                    {!mobileMode ?
                        <div className="navbar-submit" onClick={this.handleSubmit}>
                            <img alt="Submit" src={require('../../assets/images/ic_checklist.png')} />
                            <label className={mobileMode ? "hidden" : "navbar-submit-label"}>SELESAI</label>
                        </div> : null
                    }

                </div>
                <div className="content-tryout-container">
                    <div className="content-tryout-view">
                        {questions.length > 0 ?
                            <div className="content-question">
                                {questionText ?
                                    <img alt="soal" src={`${questionText}`} />
                                    : null}
                                <img alt="soal" src={`${questionImg}`} />

                            </div>
                            : null}
                        <div className="content-answers">
                            <div className="content-answers-button-group">
                                {questions.length > 0 ?
                                    questionOption ?
                                        ['A', 'B', 'C', 'D', 'E'].map((value, index) => {
                                            return <button
                                                id={value}
                                                value={value}
                                                key={index}
                                                style={{
                                                    backgroundColor: answers[currentQuestion] === value ?
                                                        "#32b4a6" : "#ffffff",
                                                    color: answers[currentQuestion] === value ?
                                                        "#ffffff" : "#000000",
                                                }}
                                                onClick={(e) => this.handleAnswers(e)}>{value}</button>
                                        }) :
                                        ['A', 'B', 'C', 'D'].map((value, index) => {
                                            return <button
                                                id={value}
                                                value={value}
                                                key={index}
                                                style={{
                                                    backgroundColor: answers[currentQuestion] === value ?
                                                        "#32b4a6" : "#ffffff",
                                                    color: answers[currentQuestion] === value ?
                                                        "#ffffff" : "#000000",
                                                }}
                                                onClick={(e) => this.handleAnswers(e)}>{value}</button>
                                        }) : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="content-navigator">
                        <button className="prev" onClick={this.handlePrev}>SEBELUMNYA</button>
                        {/* <button
                            className="mark"
                            onClick={(e) => this.handleMark(e)}
                            style={{
                                backgroundColor: status[currentQuestion] ? "#E8a317" : "#000000"
                            }}
                        >
                            TANDAI RAGU
                            </button> */}
                        <button
                            className="next"
                            onClick={this.handleNext}
                        >SELANJUTNYA</button>
                    </div>
                </div>
                {showModal ?
                    <div className="modal-dashboard">
                        {loading ?
                            <ModalField
                                label="Memulai tryout.."
                                showButton={false}
                            /> :
                            startedTryout && !finishedTryout && this.props.tryout.time_left >= 60 ?
                                <ModalField
                                    label="Mohon tunggu, tryout akan segera dimulai.."
                                    showButton={false}
                                />
                                :
                                changePack ?
                                    <ModalField
                                        label="Mohon menunggu 1 menit untuk menuju subtes berikutnya"
                                        showButton={false}
                                    />
                                    : null
                        }
                        {finishedTryout ?
                            waitTime ?
                                waiting ?
                                    <ModalField
                                        label="Mohon tunggu hingga waktu tryout selesai."
                                        showButton={false}
                                    />
                                    :
                                    <ModalField
                                        label="Apa Anda yakin? Anda tetap harus menunggu waktu tryout selesai."
                                        showButton={true}
                                        buttonFunctions={[() => this.setState({ waiting: true }), () => this.setState({ waitTime: false, finishedTryout: false, showModal: false })]}
                                        buttonLabels={["OK", "Batal"]}
                                        buttonColors={["#2a9df4", "#c21807"]}
                                        buttonLabelColors={["#ffffff", "#ffffff"]}
                                    />
                                :
                                <ModalField
                                    label="Terima kasih telah mengikuti Tryout Iwa K"
                                    showButton={true}
                                    buttonFunctions={[() => {
                                        // event.preventDefault();
                                        this.props.history.replace("/dashboard");
                                        window.location.reload();
                                    }]}
                                    buttonLabels={["OK"]}
                                    buttonColors={["#2a9df4"]}
                                    buttonLabelColors={["#ffffff"]}
                                />
                            : null
                        }
                    </div> : null
                }
                {this.props.tryout.error_messages.toString().includes("Network Error") ? <div className="offline">OFFLINE</div> : null}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        tryout: state.tryout,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        Submit: (tryoutId, answerId, topic, answers, type) => dispatch(requests.tryout.postTryout(tryoutId, answerId, topic, answers, type)),
        GetQuestions: (token, type) => dispatch(requests.tryout.getTryout(token, type)),
        GetUserInfo: () => dispatch(requests.auth.getUserInfo()),
        GetCurrentAnswer: (tryoutId, answerId, topic, type) => dispatch(requests.tryout.getTryoutAnswer(tryoutId, answerId, topic, type)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tryout))
