import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./index.css";
import { ALPHABET } from "../../constants";

export const AnswerField = (props) => {
  const { question, answerValue, onAnswer } = props;
  const [textAnswer, setTextAnswer] = useState(answerValue ?? "");
  const [radioAnswer, setRadioAnswer] = useState(answerValue ?? "NNNN");

  const handleAnswerRadio = useCallback(
    (idx) => (e) => {
      const result = [...radioAnswer];
      result[idx] = result[idx] === e.target.value ? "N" : e.target.value;
      setRadioAnswer(result.join(""));
      onAnswer(result.join("") || "-");
    },
    [onAnswer, radioAnswer]
  );

  const handleAnswerPg = useCallback(
    (e) => {
      if (answerValue === e.target.value) {
        onAnswer("-");
      } else {
        onAnswer(e.target.value);
      }
    },
    [answerValue, onAnswer]
  );

  const handleAnswerText = useCallback((e) => {
    setTextAnswer(e.target.value);
  }, []);

  useEffect(() => {
    if (question.type !== 3) return;
    setTextAnswer(answerValue === "-" ? "" : answerValue);
  }, [answerValue, question.type]);

  useEffect(() => {
    if (question.type !== 2) return;
    setRadioAnswer(
      answerValue === "-" || answerValue === ""
        ? [...Array(question.choices_quantity).keys()].reduce(
            (prev) => prev + "N",
            ""
          )
        : answerValue
    );
  }, [answerValue, question.choices_quantity, question.type]);

  useEffect(() => {
    if (question.type !== 3) return;
    const timeoutId = setTimeout(() => {
      onAnswer(textAnswer || "-");
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [onAnswer, question.type, textAnswer]);

  // #region RENDER CHOICES

  const renderPg = useMemo(() => {
    return ALPHABET.filter((_, idx) => idx < question.choices_quantity).map(
      (el) => (
        <button
          id={el}
          value={el}
          key={el}
          style={{
            backgroundColor: answerValue === el ? "#32b4a6" : "#ffffff",
            color: answerValue === el ? "#ffffff" : "#000000",
          }}
          onClick={handleAnswerPg}
        >
          {el}
        </button>
      )
    );
  }, [answerValue, handleAnswerPg, question.choices_quantity]);

  const renderRadio = useMemo(() => {
    return [...Array(question.choices_quantity).keys()].map((el, idx) => {
      return (
        <div className="radio">
          <label className="radio-item">
            {question.benar}
            <input
              type="checkbox"
              value="B"
              checked={radioAnswer[idx] === "B"}
              onChange={handleAnswerRadio(idx)}
            />
            <span class="checkmark"></span>
          </label>
          <label className="radio-item">
            {question.salah}
            <input
              type="checkbox"
              value="S"
              checked={radioAnswer[idx] === "S"}
              onChange={handleAnswerRadio(idx)}
            />
            <span class="checkmark"></span>
          </label>
        </div>
      );
    });
  }, [
    question.choices_quantity,
    question.benar,
    question.salah,
    radioAnswer,
    handleAnswerRadio,
  ]);

  const renderChoices = useMemo(() => {
    switch (question.type) {
      case 2:
        return renderRadio;
      case 3:
        return (
          <input
            type="number"
            placeholder="Masukan jawaban kamu disini"
            value={textAnswer}
            onChange={handleAnswerText}
          />
        );
      default:
        return renderPg;
    }
  }, [question.type, renderRadio, textAnswer, handleAnswerText, renderPg]);

  // #endregion

  return (
    <div
      className="answer-group"
      style={{ flexDirection: question.type === 2 && "column" }}
    >
      {renderChoices}
    </div>
  );
};
