import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AnswerField } from "../../components/AnswerField";
import ModalField from "../../components/ModalField";
import { DEFAULT_CHOICES_QUANTITY } from "../../constants";
import requests from "../../redux/requests";
import HeaderTryout from "./HeaderTryout";
import NavbarTryout from "./NavbarTryout";
import { Countdown, SaveArrayToLocal, SaveStartTime } from "./action";

class TryoutRevision extends Component {
  state = {
    currentTopic: "",
    answerId: "",
    questions: [
      {
        text: "",
        soal: "../../assets/images/logo-transparent.png",
        type: 1,
        choices_quantity: DEFAULT_CHOICES_QUANTITY,
        benar: null,
        salah: null,
      },
      {
        text: "",
        soal: "../../assets/images/logo-transparent.png",
        type: 2,
        choices_quantity: DEFAULT_CHOICES_QUANTITY,
        benar: "Betul",
        salah: "Salah",
      },
      {
        text: "",
        soal: "../../assets/images/logo-transparent.png",
        type: 3,
        choices_quantity: DEFAULT_CHOICES_QUANTITY,
        benar: null,
        salah: null,
      },
    ],
    currentQuestion: 0,
    answers: ["-", "-", "-"],
    topicLabel: "",
    startNumber: 0,
    order: -1,
    nomor: 0,
    numbers: [],

    startedTryout: false,
    finishedTryout: false,
    changePack: false,
    loading: false,
    showModal: false,
    waiting: false,
    waitTime: false,
    initialized: false,
    mobileMode: false,
    hideQuestions: true,
  };

  async componentDidMount() {
    // add event listener for resizing
    window.addEventListener("resize", this.resize);
    window.addEventListener("focus", this.refresh);
    console.log("version 1.0.2");

    this.resize();

    await this.props.GetUserInfo();
    var token_tryout = this.props.auth.user_info
      ? this.props.auth.user_info.token_tryout
      : null;
    var type = this.props.auth.user_info ? this.props.auth.user_info.type : 1;

    // this.setupLogging();

    var initialized = this.state.initialized;
    if (!initialized) {
      if (
        token_tryout === "null" ||
        token_tryout === "" ||
        token_tryout === undefined ||
        token_tryout === null ||
        token_tryout === "undefined"
      ) {
        console.log("Tidak ada token tryout");
        this.props.history.replace("/dashboard");
      } else {
        if (token_tryout && type) {
          this.init();
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
    window.removeEventListener("focus", this.refresh);
  }

  // NOTE: If we need logging to file, uncomment this and call this function after every API calls
  // setupLogging = () => {
  //     var fs = require('fs');
  //     var util = require('util');
  //     var log_file = fs.createWriteStream(__dirname + `${this.props.auth.user_info.noinduk}/debug.log`, { flags: 'w' });
  //     var log_stdout = process.stdout;

  //     console.log = function (d) { //
  //         log_file.write(util.format(d) + '\n');
  //         log_stdout.write(util.format(d) + '\n');
  //     };
  // }

  init = async () => {
    console.log("Init tryout");
    var token_tryout = this.props.auth.user_info
      ? this.props.auth.user_info.token_tryout
      : "";
    var type = this.props.auth.user_info ? this.props.auth.user_info.type : 1;
    await this.props.GetTryout(token_tryout, type);

    // UI purpose
    var timeLeft = 0;
    var startedTryout = false;
    var finishedTryout = false;
    var changePack = false;
    var showModal = false;
    var waitTime = false;
    var waiting = false;
    var stringTopic = "";
    var label = document.getElementById("time-label");

    // tryout purpose
    var questions = this.props.tryout
      ? this.props.tryout.current_questions
      : [];
    var answers = new Array(questions.length).fill("-");
    var currentQuestion = 0;
    var idTryout = this.props.tryout && this.props.tryout.id_tryout;
    var idAnswer = this.props.tryout && this.props.tryout.id_answer;
    var nomor = this.props.tryout ? parseInt(this.props.tryout.nomor) : 0;
    var numbers = this.props.tryout && this.props.tryout.numbers;
    var currentTopic = this.props.tryout && this.props.tryout.current_topic;
    var order = this.props.tryout ? parseInt(this.props.tryout.order) : -1;

    // check state now
    switch (currentTopic) {
      // when tryout change from TPS to TPA
      case "istirahat":
        stringTopic = "Menunggu..";
        timeLeft = this.props.tryout.time_left;
        startedTryout = true;
        changePack = true;
        showModal = true;
        questions = [];

        label = document.getElementById("time-label");
        Countdown(this, timeLeft, label, "main");
        break;
      // when tryout is finished
      case "end":
        finishedTryout = true;
        startedTryout = false;
        showModal = true;
        questions = [];
        break;
      // when the user came too early
      case "":
        stringTopic = "Menunggu..";
        timeLeft = this.props.tryout.time_left;
        startedTryout = false;
        showModal = true;

        label = document.getElementById("time-label");
        Countdown(this, timeLeft, label, "main");
        break;
      // set current tryout
      default:
        stringTopic = currentTopic.replace(/_/g, " ").toUpperCase();
        await this.props.GetCurrentAnswer(
          idTryout,
          idAnswer,
          currentTopic,
          type,
          order
        );

        timeLeft = this.props.tryout ? this.props.tryout.time_left : 0;
        answers = this.props.tryout && this.props.tryout.current_answers;
        questions = this.props.tryout && this.props.tryout.current_questions;
        numbers = numbers = this.props.tryout && this.props.tryout.numbers;
        idAnswer = this.props.tryout.id_answer;
        idTryout = this.props.tryout.id_tryout;
        order = this.props.tryout.order;
        startedTryout = true;

        if (!answers || (answers.length === 1 && !answers[0])) {
          console.log("Jawaban masih kosong", order);
          answers = new Array(questions.length).fill("-");
        }

        // Start counter
        label = document.getElementById("time-label");
        Countdown(this, timeLeft, label, "main");
        break;
    }

    this.setState({
      currentTopic: stringTopic,
      answerId: idAnswer,
      topicLabel: stringTopic,
      questions: questions,
      answers: answers,
      initialized: true,
      currentQuestion: currentQuestion,
      startedTryout: startedTryout,
      finishedTryout: finishedTryout,
      changePack: changePack,
      loading: false,
      showModal: showModal,
      waiting: waiting,
      waitTime: waitTime,
      numbers,
      nomor,
      order,
    });
  };

  resize = () => {
    this.setState({
      mobileMode: window.innerWidth <= 768 ? true : false,
      hideQuestions: window.innerWidth <= 768 ? true : false,
    });
  };

  refresh = () => {
    window.location.reload();
  };

  handlePrev = () => {
    let prevQuestion = this.state.currentQuestion - 1;
    if (prevQuestion < 0) {
      prevQuestion = this.state.questions.length - 1;
    }

    this.setState({ currentQuestion: prevQuestion });
  };

  handleNext = () => {
    let nextQuestion = this.state.currentQuestion + 1;
    if (nextQuestion === this.state.questions.length) {
      nextQuestion = 0;
    }

    this.setState({ currentQuestion: nextQuestion });
  };

  handleStartTryout = () => {
    var label = document.getElementById("time-label");
    SaveStartTime();
    Countdown(this, localStorage.getItem("duration"), label, "main");
    this.setState({ showModal: false, startedTryout: true });
  };

  handleMark = (e) => {
    var currentMark = this.state.status;
    if (currentMark[this.state.currentQuestion] === 1) {
      currentMark[this.state.currentQuestion] = 0;
    } else {
      currentMark[this.state.currentQuestion] = 1;
    }
    e.target.blur();

    this.setState({ status: currentMark });
    SaveArrayToLocal(currentMark, "marks");
  };

  handleAnswers = (_answer) => {
    let currentAnswer = this.state.answers;
    currentAnswer[this.state.currentQuestion] = _answer;

    this.setState({ answers: currentAnswer });
    console.log({ currentAnswer });
    SaveArrayToLocal(currentAnswer, "answers");

    this.sendAnswersToServer();
  };

  sendAnswersToServer = async () => {
    if (!this.props.tryout) return;

    var idAnswer = this.props.tryout.id_answer ?? this.state.answerId;
    var idTryout = this.props.tryout.id_tryout;
    var currentTopic = this.props.tryout.current_topic;
    var currentOrder = this.state.order;
    var answers = this.state.answers;
    let stringAnswer = answers.join(";");
    this.props.Submit({
      tryoutId: idTryout,
      answerId: idAnswer,
      topic: currentTopic,
      answers: stringAnswer,
      type: this.props.auth.user_info.type,
      order: currentOrder,
    });
  };

  handleSubmit = () => {
    this.sendAnswersToServer();

    this.setState({
      showModal: true,
      finishedTryout: true,
      waitTime: true,
    });
  };

  render() {
    const {
      answers,
      mobileMode,
      hideQuestions,
      questions,
      currentQuestion,
      currentTopic,
      numbers,
      showModal,
      loading,
      startedTryout,
      finishedTryout,
      changePack,
      waitTime,
      waiting,
    } = this.state;

    const questionImg = questions[currentQuestion]
      ? questions[currentQuestion].soal
      : "";
    const questionText = questions[currentQuestion]
      ? questions[currentQuestion].text
      : "";
    const username = this.props.auth.user_info
      ? this.props.auth.user_info.nama
      : "Fulan";
    const noinduk = this.props.auth.user_info
      ? this.props.auth.user_info.noinduk
      : "000000";
    const sekolah = this.props.auth.user_info
      ? this.props.auth.user_info.sekolah
      : "Asal Sekolah";

    return (
      <div className="parent-container-tryout">
        <HeaderTryout
          mobileMode={mobileMode}
          onHideQuestions={(hide) => this.setState({ hideQuestions: hide })}
          currentQuestion={currentQuestion}
          currentTopic={currentTopic}
          answers={answers}
          numbers={numbers}
          onSubmit={this.handleSubmit}
        />
        <NavbarTryout
          mobileMode={mobileMode}
          questions={questions}
          hideQuestions={hideQuestions}
          currentQuestion={currentQuestion}
          answers={answers}
          numbers={numbers}
          username={username}
          noinduk={noinduk}
          sekolah={sekolah}
          onHideQuestions={(hide) => this.setState({ hideQuestions: hide })}
          onSelectQuestion={(index) =>
            this.setState({ currentQuestion: index })
          }
          onSubmit={this.handleSubmit}
        />
        <div className="content-tryout-container">
          <div className="content-tryout-view">
            {questions.length > 0 ? (
              <div className="content-question">
                {questionText ? (
                  <img alt="soal" src={`${questionText}`} />
                ) : null}
                <img alt="soal" src={`${questionImg}`} />
              </div>
            ) : null}
            <div className="content-answers">
              {questions.length && (
                <AnswerField
                  question={questions[currentQuestion]}
                  answerValue={answers[currentQuestion]}
                  onAnswer={this.handleAnswers}
                />
              )}
            </div>
          </div>
          <div className="content-navigator">
            <button className="prev" onClick={this.handlePrev}>
              SEBELUMNYA
            </button>
            {/* NOTE: uncomment if need to use ragu-ragu button */}
            {/* <button
                className="mark"
                onClick={(e) => this.handleMark(e)}
                style={{
                    backgroundColor: status[currentQuestion] ? "#E8a317" : "#000000"
                }}
            >
                TANDAI RAGU
            </button> */}
            <button className="next" onClick={this.handleNext}>
              SELANJUTNYA
            </button>
          </div>
        </div>
        {showModal ? (
          <div className="modal-dashboard">
            {loading ? (
              <ModalField label="Memulai tryout.." showButton={false} />
            ) : startedTryout &&
              !finishedTryout &&
              this.props.tryout.time_left >= 60 ? (
              <ModalField
                label="Mohon tunggu, tryout akan segera dimulai.."
                showButton={false}
              />
            ) : changePack ? (
              <ModalField label={`Mohon tunggu..`} showButton={false} />
            ) : null}
            {finishedTryout ? (
              waitTime ? (
                waiting ? (
                  <ModalField
                    label="Mohon tunggu hingga waktu tryout selesai."
                    showButton={false}
                  />
                ) : (
                  <ModalField
                    label="Apa Anda yakin? Anda tetap harus menunggu waktu tryout selesai."
                    showButton={true}
                    buttonFunctions={[
                      () => this.setState({ waiting: true }),
                      () =>
                        this.setState({
                          waitTime: false,
                          finishedTryout: false,
                          showModal: false,
                        }),
                    ]}
                    buttonLabels={["OK", "Batal"]}
                    buttonColors={["#2a9df4", "#c21807"]}
                    buttonLabelColors={["#ffffff", "#ffffff"]}
                  />
                )
              ) : (
                <ModalField
                  label="Terima kasih telah mengikuti Tryout Iwa K"
                  showButton={true}
                  buttonFunctions={[
                    () => {
                      // event.preventDefault();
                      this.props.history.replace("/dashboard");
                      window.location.reload();
                    },
                  ]}
                  buttonLabels={["OK"]}
                  buttonColors={["#2a9df4"]}
                  buttonLabelColors={["#ffffff"]}
                />
              )
            ) : null}
          </div>
        ) : null}
        {this.props.tryout.error_messages
          .toString()
          .includes("Network Error") ? (
          <div className="modal-dashboard">
            <ModalField
              label={
                <div className="offline-modal">
                  <img
                    alt="profile-img"
                    src={require("../../assets/images/ic_offline.png")}
                  />
                  <label>
                    Internet kamu terputus! Coba cek lagi jaringan internet kamu
                    lalu refresh halaman ini ya
                  </label>
                </div>
              }
              showButton={false}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    tryout: state.tryout,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetUserInfo: () => dispatch(requests.auth.getUserInfo()),
    GetTryout: (token, type) =>
      dispatch(requests.tryout.getTryout(token, type)),
    GetCurrentAnswer: (tryoutId, answerId, topic, type, order) =>
      dispatch(
        requests.tryout.getTryoutAnswer(tryoutId, answerId, topic, type, order)
      ),
    Submit: (props) => dispatch(requests.tryout.postTryout(props)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TryoutRevision)
);
